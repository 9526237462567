/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { blue } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Form, Input, Space, Spin, Statistic, Typography } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import EVAPI from '../lib/ev_lib/main';
import { Column } from '@ant-design/plots';
import { URLQuery } from '../util/url_queries';

interface WidgetProps {
  title:string,
  linkedToPackingOrder?:'y'|'n'
}

export default function CargoGraphPerWarehouse(props:WidgetProps) {
  let profile:any = localStorage.getItem('profile');

  try {
    profile = JSON.parse(profile);
  }
  catch(e) {
    console.error('Error parsing the user profile', e);
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cargoesCount, setCargoesCount] = useState<number>(0);
  const [warehouses, setWarehouses] = useState<{uuid:string,name:string}[]>([]);
  const [dataSet, setDataSet] = useState<{uuid?:string,name:string,count:any}[]>([]);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    setCountPerWarehouse();
  }, [warehouses]);

  const getWarehouses = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getWarehouses();

    setIsLoading(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          uuid: item.uuid,
          name: item.name,
        });
      });
    }

    setWarehouses(sortedData);
    return;
  }

  const setCountPerWarehouse = async () => {
    if(
      !warehouses
      || warehouses.length < 1
    ) {
      // nothing to process
      return;
    }

    var tempData:{name:string,count:any}[] = [];

    for(var i = 0; i < warehouses.length; i++) {
      var count:any = await getCargoes(warehouses[i].uuid!);

      tempData.push({
        name: warehouses[i].name,
        count: count
      });
    }
      
    setDataSet(tempData)

    return;
  }

  const getCargoes = async (warehouseUuid:string) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.count = true;

    EV.warehousesUuids = warehouseUuid;

    if(props.linkedToPackingOrder) EV.linkedToPackingOrder = props.linkedToPackingOrder;

    var res:any = await EV.getCargoes();
    
    if(res.data?.count) {
      return Number(res.data.count); 
    }
    else return 0;
  }
  
  return <>
    <Card>
      {props.title && <Typography.Title level={4} style={{margin: 0, marginBottom: 30}}>
        {props.title}
      </Typography.Title> }
      <Column
        data={dataSet}
        xField={'name'}
        yField={'count'}
        label={{
          position: 'middle',
          style: {
            fill: '#fff',
            opacity: 0.6
          }
        }}
        xAxis={{
          label: {
            autoHide: true,
            autoRotate: false
          }
        }}
        meta={{
          name: {
            alias: 'Warehouse'
          },
          count: {
            alias: 'Count'
          }
        }}
      />
    </Card>
  </>
}