/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Alert, Button, Card, Col, Form, Input, Layout, Modal, Popconfirm, Row, Space, Table, theme, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

import EVAPI from '../lib/ev_lib/main';

import { config } from '../config';
import { URLQuery } from '../util/url_queries';
import { CheckOutlined, CloseOutlined, DeleteOutlined, LeftOutlined, PlusCircleFilled, ReloadOutlined, SendOutlined } from '@ant-design/icons';

import momentTz from 'moment-timezone';
import moment from 'moment-timezone';

export function MFABanner() {
  const [email, setEmail] = useState<string|null>(null);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const [isLoadingMFAMethods, setIsLoadingMFAMethods] = useState<boolean>(false);
  const [mfaMethods, setMFAMethods] = useState<any>([])

  useEffect(() => {
    // check if the user dismissed the banner 
    if(
      !localStorage.getItem('profile')
      || localStorage.getItem('userDismissedMFABanner')
    ) {
      setShowBanner(false);
      return;
    }
    else {
      getMFAMethods();
    }
  }, []);
  
  // get the authentication methods from the user
  const getMFAMethods = async () => {
    if(isLoadingMFAMethods) return ;
    setIsLoadingMFAMethods(true);
    
    var profile:any = localStorage.getItem('profile');
    
    if(!profile) return;

    try {
      if(typeof profile === 'string') {
        profile = JSON.parse(profile);
      }

      if(profile.email) {
        setEmail(profile.email)
      }      
    }
    catch(e) {
      console.error(
        'Failed to process the profile email',
        e
      );
    }

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.users_uuid = profile?.uuid;
    EV.showAll = true;
    
    var res:any = await EV.getMFAMethods();
    
    if(
      res 
      && res.status === 'success'
      && res.data
      && res.data?.length > 0
    ) {
      setMFAMethods(res.data);
    }

    else if (res.data.length < 1) {
      setShowBanner(true);
    }

    setIsLoadingMFAMethods(false);
    return;
  }
  

  if(!showBanner) return null;

  return <>
    <Card
      style={{
      }}
    >
      <Typography.Title level={4} style={{padding: 0, margin: 0}}>
        Multi Factor Authentication Required
      </Typography.Title>
      <Typography.Paragraph>
        To enhance security, we are implementing Multi-Factor Authentication (MFA). Starting from your next login, 
        you will need to verify your login using the provided methods.
      </Typography.Paragraph>
      <Space>
        <Button
          type={"primary"}
          danger
          children={'Manage MFA Methods'}
          onClick={() => window.location.href = '/system/settings/users/view/me'}
        />
        <Button
          icon={<CloseOutlined />}
          type={"default"}
          danger
          children={'Don\'t show again'}
          onClick={() => {
            localStorage.setItem('userDismissedMFABanner', 'y');
            setShowBanner(false);
          }}
        />
      </Space>
    </Card>
  </>
}

export function MFAManagement(props:any) {
  const [form] = Form.useForm();

  const [userUuid, setUserUuid] = useState<string|null>(props.userUuid);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);
  const [mfaMethods, setMFAMethods] = useState<any>([]);
  const [isLoadingMFAMethods, setIsLoadingMFAMethods] = useState<boolean>(false);

  const [showNewMethodForm, setShowNewMethodForm] = useState<boolean>(false);
  const [mfaFormInput, setMFAFormInput] = useState<any>({ type: 'email' });

  const [otpSent, setOtpSent] = useState<boolean>(false);

  useEffect(() => {
    getMFAMethods();
  }, []);

  const getMFAMethods = async () => {
    if(isLoadingMFAMethods) return ;
    setIsLoadingMFAMethods(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.users_uuid = userUuid;
    EV.showAll = true;
    
    var res:any = await EV.getMFAMethods();

    if(!res) {
      setErrors([{
        en: 'Network error'
      }]);
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else {
      setMFAMethods(res.data);
    }

    setIsLoadingMFAMethods(false);
    return;
  }
  
  const sendOtp = async () => {
    if(isProcessing) return;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.lang = 'en';
    EV.baseAPI = localStorage.getItem('target_domain');
    
    EV.email = mfaFormInput.email;

    var res:any = await EV.sendOtp();

    if(res && res.status === 'success') {
      setOtpSent(true);
    }

    setIsProcessing(false);
    return;
  }

  const processVerificationCode = async () => {
    if(isProcessing) return;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.lang = 'en';
    EV.baseAPI = localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    EV.type = mfaFormInput.type;
    EV.email = mfaFormInput.email;
    EV.otp_code = mfaFormInput.otp_code;

    var res:any = await EV.createMFAMethod();
    
    if(!res || res.status !== "success") {
      setErrors([{
        en: "Failed to process the request to verify the MFA"
      }])

      setIsProcessing(false);
      return;
    }

    else {
      // reset everything
      setMFAFormInput({ type: 'email' });
      setErrors([]);
      setShowNewMethodForm(false);
      setOtpSent(false);

      getMFAMethods();
    }

    setIsProcessing(false);
    return;
  }

  const deleteMFAMethod = async (uuid:string) => {
    if(isProcessing) return;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.lang = 'en';
    EV.baseAPI = localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    EV.uuid = uuid;

    var res:any = await EV.deleteMFAMethod();
    
    if(!res || res.status !== "success") {
      setErrors([{
        en: "Failed to process the request to verify the MFA"
      }])

      setIsProcessing(false);
      return;
    }

    else {
      setErrors([]);
      getMFAMethods();
    }

    setIsProcessing(false);
    return;
  }

  return <>
    <Table
      pagination={false}
      dataSource={mfaMethods}
      loading={isLoadingMFAMethods}
      columns={[
        {
          key: 'method', 
          title: 'Method', 
          dataIndex: 'method',
          render: (_:any, record:any) => {
            try {
              if(!record.data) return '/** Error'

              if(typeof record.data === 'string') {
                record.data = JSON.parse(record.data);
              }

              if(record.type === 'email') {
                return record.data.email
              }
              else if(record.type === 'phone') {
                return record.data.country_code||'...' + ' - ' + record.data.phone||'...'
              }
              else {
                return '/** Not Supported'
              }
            }
            catch(e) {
              console.error(
                'Failed to parse the data from the MFA with UUID: ',
                record.uuid,
                record.data,
                e
              );

              return '/** Parsing Error'
            }
          }
        },
        {
          key: 'created_at', 
          title: 'Added On', 
          dataIndex: 'created_at',
          width: 170,
          render: (_:any, record:any) => {
            return momentTz(record.created_at)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY hh:mm A');
          }
        },
        {
          key: 'action',
          title: 'Action', 
          fixed: 'right' as 'right',
          width: 100,
          render: (_:any, record:any) => <Space size={'small'}>
            <Popconfirm
              title="Delete MFA Method"
              description={`Are you sure to delete the selected MFA method?`}
              onConfirm={() => deleteMFAMethod(record.uuid)}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button
                danger
                type='link'
                icon={<DeleteOutlined />}
                title={'Delete'}
              />
            </Popconfirm>
            
          </Space>
        },
      ]} 
    />

    <div style={{marginBottom: 10}} />

    <Modal
      title={'New MFA Method'}
      open={showNewMethodForm}
      onCancel={() => {
        setErrors([]);
        setShowNewMethodForm(false);
        setOtpSent(false);
        form.resetFields();
        setMFAFormInput({ type: 'email' });
      }}
      footer={<Space>
        {(showNewMethodForm) && <Button 
          type={'default'}
          loading={isProcessing}
          children={'Cancel'}
          onClick={() => {
            setErrors([]);
            setShowNewMethodForm(false);
            setOtpSent(false);
            form.resetFields();
            setMFAFormInput({ type: 'email' });
          }}
        />}
      </Space>}
      children={<>
        <Form
          form={form}
          layout="vertical"
          initialValues={mfaFormInput}
        >
          {(showNewMethodForm && !otpSent) && <>
            <Form.Item
              label={'Email Address'} 
              name={'email'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setMFAFormInput({
                  ...mfaFormInput,
                  email: e.target.value
                })}
              />
            </Form.Item>
          
            <Form.Item>
              <Space>
                <Button 
                  icon={<SendOutlined />}
                  type={"primary"}
                  disabled={!mfaFormInput.email}
                  loading={isProcessing}
                  children={'Send OTP'}
                  onClick={sendOtp}
                />
              </Space>
            </Form.Item>
          </>}
          
          {(showNewMethodForm && otpSent) && <>
            <Form.Item
              label={'MFA Code'} 
              name={'mfa_code'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setMFAFormInput({
                  ...mfaFormInput,
                  otp_code: e.target.value
                })}
              />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button 
                  icon={<CheckOutlined />}
                  type={"primary"}
                  loading={isProcessing}
                  children={'Verify & Save'}
                  onClick={processVerificationCode}
                />
                <Button 
                  icon={<ReloadOutlined />}
                  type={"dashed"}
                  disabled={!mfaFormInput.email}
                  loading={isProcessing}
                  children={'Resend OTP'}
                  onClick={sendOtp}
                />
              </Space>
            </Form.Item>
          </>}
        </Form>
      </>}
    />

    <Form.Item>
      <Space>
        <Button 
          icon={<PlusCircleFilled />}
          type={"primary"}
          loading={isProcessing}
          children={'Add New Method'}
          onClick={() => setShowNewMethodForm(true)}
        />
      </Space>
    </Form.Item>
  </>
}

export default function MFA() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mfaMethods, setMFAMethods] = useState<any>(location?.state?.mfa_methods||undefined);
  const [selectedMFA, setSelectedMFA] = useState<any>(undefined);

  const [otpSent, setOTPSent] = useState<boolean>(false);
  const [mfaCode, setMFACode] = useState<string|null>(null);

  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    parseMFAMethods();
  }, [])

  const parseMFAMethods = async () => {
    var mfaMethods = location?.state?.mfa_methods;

    if(!mfaMethods) {
      setIsLoading(false);
      return;
    }

    for(var i = 0; i < mfaMethods.length; i++) {
      try {
        if(typeof mfaMethods[i].data === "string") {
          mfaMethods[i].data = JSON.parse(mfaMethods[i].data);
        }

        setMFAMethods(mfaMethods);
      }
      catch(e) {
        console.error(
          `Failed to process mfaMethod (${i})`,
          e
        );

        setErrors([{
          en: 'Failed to process MFA email'
        }]);

        return;
      }
    }

    setIsLoading(false);
    return;
  }
  
  const sendOtp = async (email:string) => {
    if(isLoading) return;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.lang = 'en';
    EV.baseAPI = localStorage.getItem('target_domain');
    
    EV.email = email;

    var res:any = await EV.sendOtp();

    if(res && res.status === 'success') {
      setSelectedMFA({
        type: "email",
        email: email
      });

      setOTPSent(true);
    }

    setIsLoading(false);
    return;
  }

  const processVerificationCode = async () => {
    if(isLoading) return;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.lang = 'en';
    EV.baseAPI = localStorage.getItem('target_domain');
    
    EV.type = selectedMFA.type;
    EV.email = selectedMFA.email;
    EV.otp_code = mfaCode;
    EV.deviceUuid = localStorage.getItem('device_uuid');

    var res:any = await EV.loginMFAVerify();
    
    if(!res || res.status !== "success") {
      setErrors([{
        en: "Failed to process the request to verify the MFA"
      }])

      setIsLoading(false);
      return;
    }

    var profile:any = res.data;

    localStorage.setItem('auth_token', profile?.auth_token);
    localStorage.setItem('profile', JSON.stringify(profile));

    getUser(profile.uuid);

    setIsLoading(false);
    return;
  }

  const getUser = async (uuid:string) => {
    var tempErrors = [];

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;
    
    var res:any = await EV.getUser();

    if(
      !res
      || res.status !== 'success'
    ) {
      tempErrors.push({ 
        en: 'Failed to download user information' 
      })
    }

    if(tempErrors.length > 0) {
      setErrors(tempErrors);
      return null;
    }

    let redirect = location?.state?.redirect;
    
    if(redirect) {
      window.location.href = process.env.REACT_APP_PORTAL_URI + redirect;
    }
    else {
      window.location.href = process.env.REACT_APP_PORTAL_URI || '';
    }
  }

  return <Layout>
    <Content
      className="full-screen-container"
      style={{background: colorBgContainer}}
    >
      <Row style={{width: '100%'}}>
        <Col span={10} offset={8}>
          <div style={{marginBottom: 0, width: 200, height: 40}}>
            <img src={require('../framework/assets/logo.png')} style={{height: '100%'}} />
          </div>

          <Typography.Title level={3}>Identification Verification</Typography.Title> 
          
          {(errors && errors.length > 0) && <Alert
            type={'error'}
            message={'Login Error'}
            description={<ul>{errors.map((error, key) => {
              if(typeof error === 'object') {
                error = error.en
              }

              return <li>{error}</li>;
            })}</ul>}
            closable
            onClose={() => setErrors([])}
            style={{marginBottom: 20}}
          />}

          {(!selectedMFA || !selectedMFA.email) && mfaMethods?.map((mfaMethod:any) => {
            return <Card 
              style={{marginBottom: 10}}
              onClick={() => {
                sendOtp(mfaMethod?.data?.email)
              }}
            >
              {mfaMethod?.data?.email}
            </Card>
          })}

          {(otpSent) && <>
            <Form
              form={form}
              layout="vertical"
              style={{width: '100%'}}
              onFinish={processVerificationCode}
            >
              
              <Form.Item 
                label={'MFA'} 
                name={'mfa_code'}
                rules={[{ required: true, message: 'Field required'}]}
              >
                <Input.Password 
                  onChange={(e:any) => setMFACode(e.target.value)}
                />
              </Form.Item>

              <div>
                <Button 
                  type={"primary"} 
                  htmlType={"submit"}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Verify & Login
                </Button>
                <Button 
                  type={"link"} 
                  htmlType={"button"}
                  disabled={isLoading}
                >
                  Resend the Code
                </Button>
                <Button 
                  type={"link"} 
                  htmlType={"button"}
                  disabled={isLoading}
                  onClick={() => setSelectedMFA({})}
                >
                  Try Another Method 
                </Button>
              </div>
            </Form>
          </>}

          <div style={{marginTop: 25}} />

          <Button 
            icon={<LeftOutlined />}
            type={"default"}
            htmlType={"button"}
            danger
            onClick={() => {
              setIsLoading(false);
              navigate(-1);
            }}
          >
            Cancel
          </Button>

          <div style={{marginTop: 25}} />
          
          <Typography.Text 
            type={'secondary'}
            style={{width: '100%', paddingTop: 50}} 
            className={'small-font'}
          >
            eVessel Platform version {config.version}
          </Typography.Text>
        </Col>
      </Row>
    </Content>
  </Layout>
}

