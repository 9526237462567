/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { ApiFilled, AuditOutlined, CheckOutlined, CloseCircleFilled, DashOutlined, ExpandAltOutlined, FileAddFilled, FormOutlined, LeftCircleFilled, LoadingOutlined, PlusCircleFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Form, Input, message, Modal, Row, Segmented, Select, Space, Table, Tabs, Tag, Typography } from "antd";
import { URLQuery } from '../../util/url_queries';
import EVAPI from '../../lib/ev_lib/main';
import { userSettings } from '../../lib/user_settings';

import TableColumnsCuztomization, { TableColumnsProps as SelectedTableColumnProps} from '../../common/TableColumnsCuztomization';
import { blue, gold, green, red } from '@ant-design/colors';
import { resortTable } from '../../lib/table_sorting';

import moment from 'moment';
import momentTz from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

export default function PackingOrdersPayments(props:any) {

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [invoicesPerPage, setInvoicesPerPage] = useState<number>(15);
  const [invoicesCurrentPage, setInvoicesCurrentPage] = useState<number>(1);

  const [searchTerm, setSearchTerm] = useState<any>(null);

  const [selectedColumns, setSelectedColumns] = useState<string[]>();

  const tableColumnsList:{id:string, label:string, default?:boolean}[] = [
    { id: 'uuid', label: 'UUID', default: true},
    { id: 'reference_number', label: 'Ref #', default: true},
    { id: 'issue_date', label: 'Issue Date', default: true},
    { id: 'due_date', label: 'Due Date', default: true},
    { id: 'grand_total', label: 'Total', default: true},
    { id: 'balance', label: 'Balance', default: true},
  ];

  useEffect(() => {
    processPrefTableColumns();
    getInvoices();
  }, []);

  const processPrefTableColumns = () => {
    var columns:string[] = [];
    var storedColumnsString:string|null = localStorage.getItem('prefInvoicesListingTableViewColumns');
    var storedColumnArray:string[] = [];

    if(storedColumnsString) {
      storedColumnArray = storedColumnsString.split(',');
    }

    var tempColumns:string[] = [];

    tableColumnsList.map((column:SelectedTableColumnProps, key:number) => {
      if(column.default) tempColumns.push(column.id);
    });

    if(storedColumnArray.length > 0) {
      columns = storedColumnArray;
    }

    else {
      columns = tempColumns;
    }

    setSelectedColumns(columns);
    return;
  }

  const getInvoices = async () => {
    if(isLoading || !props.packingOrderUuid) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.packingOrdersUuids = props.packingOrderUuid;
    EV.searchTerm = searchTerm;
    
    EV.page = invoicesCurrentPage;
    EV.resPerPage = invoicesPerPage;

    EV.orderBy = localStorage.getItem('prefInvoicesListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefInvoicesListingOrderDirection');
    
    var res:any = await EV.getInvoices();
    
    setInvoices(res?.data);
    setInvoicesCount(res?.count);

    setIsLoading(false);
    return null;
  }

  const _renderScreen = () => {
    if(!props.packingOrderUuid) return <Alert 
      message="Save the record enable this section." 
      type="warning" 
    />
    
    return <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      dataSource={invoices} 
      scroll={{ x: 1000 }}
      loading={isLoading}
      pagination={{
        total: invoicesCount||undefined,
        showSizeChanger: true,
        pageSizeOptions: [15, 50, 100, 200],
        defaultPageSize: 15,
        pageSize: invoicesPerPage,
        onChange: (page:number, pagesSize:number) => {
          localStorage.setItem('prefInvoicesListingResPerPage', pagesSize.toString())
          setInvoicesPerPage(pagesSize);
          setInvoicesCurrentPage(page);
        }
      }}
      columns={[
        (selectedColumns?.includes('uuid')) ? {
          key: 'uuid',
          title: 'UUID',
          fixed: 'left',
          dataIndex: 'uuid',
          width: 130,
          render: (_:any, record:any) => {
            return record.uuid.substr(record.uuid.length - 10)
          }
        } : {},
        {
          key: 'status', 
          title: 'Status',
          fixed: 'left',
          width: 100,
          render: (_:any, record:any) => {
            var color = '';

            switch (record.status) {
              case 'issued':
                color = blue[5];
              break;
                
              case 'sent':
                color = gold[5];
              break;

              case 'paid':
                color = green[5];
              break;

              case 'void':
                color = red[5];
              break;
            }

            return <Tag
              color={color}
              children={record.status}
              style={{textTransform: 'capitalize'}} 
            />
          }
        },
        (selectedColumns?.includes('reference_number')) ? {
          key: 'reference_number', 
          title: 'Ref #', 
          dataIndex: 'reference_number', 
          width: 200,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'reference_number', 
                getInvoices
              )
            };
          }
        } : {},
        (selectedColumns?.includes('issue_date')) ? {
          key: 'issue_date', 
          title: 'Issue Date', 
          dataIndex: 'issue_date',
          width: 170,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'issue_date', 
                getInvoices
              )
            };
          },
          render: (_:any, record:any) => {
            if(record.issue_date) {
              return momentTz(record.issue_date)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY');
            }

            return '-';
          }
        } : {},
        (selectedColumns?.includes('due_date')) ? {
          key: 'due_date', 
          title: 'Due Date', 
          dataIndex: 'due_date',
          width: 170,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'due_date', 
                getInvoices
              )
            };
          },
          render: (_:any, record:any) => {
            if(record.due_date) {
              return momentTz(record.due_date)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY');
            }

            return '-';
          }
        } : {},
        (selectedColumns?.includes('grand_total')) ? {
          key: 'grand_total', 
          title: 'Total', 
          dataIndex: 'grand_total', 
          align: 'right',
          width: 150,
          sorter: true,
          onHeaderCell: (column) => {
            return {
              onClick: () => resortTable(
                'Invoices', 
                'grand_total', 
                getInvoices
              )
            };
          },
          render: (_:any, record:any) => {
            return <Typography.Text strong>
              {record.grand_total?.toFixed(2)}
            </Typography.Text>
          }
        } : {},
        (selectedColumns?.includes('balance')) ? {
          key: 'balance', 
          title: 'Unpaid Balance', 
          align: 'right',
          width: 150,
          render: (_:any, record:any) => {
            var value = 0;

            if(record.total_paid) {
              value = record.grand_total - record.total_paid
            }

            else value = record.grand_total;

            return value.toFixed(2);
          }
        } : {},
        {
          key: 'actions',
          title: 'Actions',
          fixed: 'right',
          width: 130,
          render: (_:any, record:any) => <Space size={'small'}>
            <Button
              type='link'
              icon={<AuditOutlined />}
              onClick={() => window.open(`/accounting/invoices/invoices/view/industrial/${record.uuid}`, '_blank')}
            />
            <Button
              type='link'
              icon={<ExpandAltOutlined />}
              onClick={() => window.open(`/accounting/invoices/invoices/view/modern/${record.uuid}`, '_blank')}
            />
            {(props.allowEdit) && <Button
              type='link'
              icon={<FormOutlined />}
              title={'Manage'}
              onClick={() => navigate(`/accounting/invoices/invoices/form/${record.uuid}`)}
            />}
          </Space>
        }
      ]}
    />
  }
  
  return <>
    {(props.showTitle) && <Typography.Title level={2} children={'Related Invoices'} />}
    {(props.customTitle) && props.customTitle}

    {_renderScreen()}
  </>
}