/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect, ReactElement } from 'react';

import { ApiFilled, CheckOutlined, CloseCircleFilled, DeleteFilled, EditOutlined, ExportOutlined, FileAddFilled, InfoCircleOutlined, LeftCircleFilled, LoadingOutlined, MailFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Empty, Form, Input, message, Modal, Popover, Row, Segmented, Select, Space, Spin, Table, Tag, Typography } from "antd";

import SelectionStyledOption from '../../../../common/SelectionStyledOption';

import BlankPage, { LayoutModalProps } from "../../../../framework/blank_page";
import EVAPI from '../../../../lib/ev_lib/main';
import { config } from '../../../../config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { setClearFormFields } from '../../../../util/clear_empty';
import { URLQuery } from '../../../../util/url_queries';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { userSettings } from '../../../../lib/user_settings';

export interface InvoiceTransactionProps {
  uuid?: string|undefined,
  invoices_uuid?:string,
  income_deposit_accounts_uuid?:string,
  income_deposit_accounts_name?:string,
  reference_number?:string|undefined,
  amount?:string|number|undefined,
  notes?:string|undefined,
  response_detials?:string|undefined,
  type?:'check'|'credit card'|'credit'|'cash'|'bank transfer',
  created_at?:string|undefined,
  deleted_at?:string|undefined,
  clear?: string[]|undefined
}

export default function InvoicesFormTransactions(props:any) {
  const depositTypes = [
    'check',
    'credit card',
    'credit',
    'cash',
    'bank transfer'
  ];

  const depositTypesOptions = [
    { key: 'check', label: 'Check', value: 'check' },
    { key: 'credit card', label: 'Credit Card', value: 'credit card' },
    { key: 'credit', label: 'Credit', value: 'credit' },
    { key: 'cash', label: 'Cash', value: 'cash' },
    { key: 'bank transfer', label: 'Bank Transfer', value: 'bank transfer' },
  ];

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();

  const [form] = Form.useForm();
  
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [openInvoiceTransactionForm, setOpenInvoiceTransactionForm] = useState<boolean>(false);

  const [isLoadingIncomeDepositAccounts, setIsLoadingIncomeDepositAccounts] = useState<boolean>(false);
  const [incomeDepositAccounts, setIncomeDepositAccounts] = useState<any[]>([]);

  const [errors, setErrors] = useState<any[]|null>(null);

  const [invoiceTransactions, setInvoiceTransactions] = useState<InvoiceTransactionProps[]>([]);
  const [invoiceTransaction, setInvoiceTransaction] = useState<InvoiceTransactionProps|undefined>(undefined);
  const [transactionsTotal, setTransactionsTotal] = useState<number|string>(0);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getIncomeDepositAccounts();

    if(uuid) getInvoicesTransactions();

    setTimeout(() => setIsReady(true), 1500);
  }, []);

  const getIncomeDepositAccounts = async () => {
    setIsLoadingIncomeDepositAccounts(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.isActive = 'y';
    EV.showAll = 'y';

    var res:any = await EV.getIncomeDepositAccounts();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull income deposit accounts data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      var data = res?.data;
      var sortedData:any = [];
  
      if(data.length > 0) {
        data.map((item:any, key:number) => {
          sortedData.push({
            value: item.uuid,
            label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.name}`
          });
        });
      }
  
      setIncomeDepositAccounts(sortedData);

    }

    setIsLoadingIncomeDepositAccounts(false);
    return null;
  }

  const getInvoicesTransactions = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoiceUuid = uuid;
    EV.showAll = 'y';

    var res:any = await EV.getInvoicesTransactions();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull invoice data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      // calculate transactions total
      var total = 0;

      res.data.map((record:any, index:number) => {
        total += record.amount;

        if(index + 1 === res.data.length) {
          setTransactionsTotal(total);
        }
      })

      // Make sure ready is set here as well as the form will not be
      // rendered when the data is suppose to be set
      setIsReady(true);
      setInvoiceTransactions(res.data);
      props.refreshStates();
    }

    setIsLoading(false);
    return null;
  }

  const processInvoiceTransaction = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(
      invoiceTransaction 
      && invoiceTransaction.uuid
    ) {
      // check clearables
      invoiceTransaction.clear = setClearFormFields(invoiceTransaction)||undefined;
    }
    
    EV = Object.assign(EV, invoiceTransaction);
    
    var res:any = null;

    if(
      invoiceTransaction 
      && invoiceTransaction.uuid
    ) res = await EV.updateInvoiceTransaction();
    else res = await EV.createInvoiceTransaction();

    if(!res || res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getInvoicesTransactions();
      setOpenInvoiceTransactionForm(false);
    }

    setIsLoading(false);
    return null;
  }

  const deleteInvoiceTransaction = async (deleteUuid:string) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = deleteUuid;

    var res:any = res = await EV.deleteInvoiceTransaction();

    if(!res || res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getInvoicesTransactions();
    }

    setIsLoading(false);
    return null;
  }

  return <>
    {contextHolder}

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}

    <Modal
      open={openInvoiceTransactionForm}
      onCancel={() => setOpenInvoiceTransactionForm(false)}
      onOk={() => form.submit()}
      children={<>
        <Typography.Title
          level={4}
          children={"Invoice Transaction"}
        />

        <Form
          form={form}
          layout="vertical"
          initialValues={invoiceTransaction}
          onFinish={processInvoiceTransaction}
        >
          <Form.Item
            label={'Type'} 
            name={'type'}
            required
          >
            <Select
              placeholder={'Select one'}
              onChange={(value) => setInvoiceTransaction({
                ...invoiceTransaction,
                type: value
              })}
              options={depositTypesOptions}
            />
          </Form.Item>
          <Form.Item
            label={'Income Deposit Account'} 
            name={'income_deposit_accounts_uuid'}
            required
          >
            <Select
              placeholder={'Select one'}
              onChange={(value) => setInvoiceTransaction({
                ...invoiceTransaction,
                income_deposit_accounts_uuid: value
              })}
              options={incomeDepositAccounts}
            />
          </Form.Item>
          <Form.Item
            label={'Amount'} 
            name={'amount'}
            required
          >
            <Input 
              placeholder={'Type here'}
              value={invoiceTransaction?.amount}
              onChange={(e) => setInvoiceTransaction({
                ...invoiceTransaction,
                amount: e.target.value
              })}
            />
          </Form.Item>
          <Form.Item
            label={'Reference #'} 
            name={'reference_number'}
          >
            <Input 
              placeholder={'Type here'}
              value={invoiceTransaction?.reference_number}
              onChange={(e) => setInvoiceTransaction({
                ...invoiceTransaction,
                reference_number: e.target.value
              })}
            />
          </Form.Item>
          <Form.Item
            label={'Notes'} 
            name={'notes'}
          >
            <TextArea 
              rows={2}
              placeholder={'Type here'}
              value={invoiceTransaction?.notes}
              onChange={(e) => setInvoiceTransaction({
                ...invoiceTransaction,
                notes: e.target.value
              })}
            />
          </Form.Item>
        </Form>
      </>}
    />
    
    <Table
      size={userSettings('compact_view') ? 'small' : undefined}
      pagination={false}
      columns={[
        {
          key: 'uuid',
          dataIndex: 'uuid',
          title: "UUID",
          width: 70,
          render: (_:any, record:any) => {
            return <Popover
              placement='top'
              title='UUID'
              content={<Typography.Text copyable>{record.uuid}</Typography.Text>}
            >
              <InfoCircleOutlined />
            </Popover>
          }
        },
        {
          key: 'type',
          dataIndex: 'type',
          title: 'Type',
          width: 200
        },
        {
          key: 'income_deposit_accounts_name',
          dataIndex: 'income_deposit_accounts_name',
          title: 'Deposit Accunt',
          width: 300
        },
        {
          key: 'reference_number',
          dataIndex: 'reference_number',
          title: 'Reference #',
          width: 200
        },
        {
          key: 'notes',
          title: 'Notes',
          width: 250,
          render: (_:any, record:any) => {
            return <div>
              <Typography.Paragraph style={{marginBottom: 0}}>
                <Typography.Text strong>{record.item}</Typography.Text>
              </Typography.Paragraph>
              {record.description && <Typography.Paragraph style={{marginBottom: 0, marginTop: 5}}>
                <Typography.Text>{record.description}</Typography.Text>
              </Typography.Paragraph>}
            </div>
          }
        },
        {
          key: 'amount',
          dataIndex: 'amount',
          title: 'Amount',
          width: 300,
          align: 'right',
          render: (_:any, record:any) => {
            return record.amount?.toFixed(2);
          }
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          width: 140,
          render: (_:any, record:any) => {
            return momentTz(record.due_date)
            .tz(moment.tz.guess())
            .format('MM/DD/YYYY');
          }
        },
        {
          key: 'action',
          title: ' ',
          align: 'right',
          render: (_:any, record:any) => {
            return <Space>
              <Button
                type='link'
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  setInvoiceTransaction(record);
                  form.setFieldsValue(record);
                  setOpenInvoiceTransactionForm(true);
                }}
              />
              <Button
                type='link'
                size="small"
                icon={<CloseCircleFilled />}
                danger
                onClick={() => {
                  deleteInvoiceTransaction(record.uuid)
                }}
              />
            </Space>
          }
        }
      ]}
      dataSource={invoiceTransactions||[]}
    />

    <div style={{marginBottom: 35}} />

    <Space>
      <Button
        icon={<PlusCircleOutlined />}
        type={"primary"}
        onClick={() => {
          var defaultFormValues:InvoiceTransactionProps  = {
            invoices_uuid: uuid,
            income_deposit_accounts_uuid: undefined,
            reference_number: undefined,
            type: undefined,
            amount: 0,
            notes: undefined
          }

          setInvoiceTransaction(defaultFormValues);
          form.setFieldsValue(defaultFormValues);
          setOpenInvoiceTransactionForm(!openInvoiceTransactionForm);
        }}
      >
        Add Transaction
      </Button>
    </Space>
  </>
}