/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { blue, green, red, yellow } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Form, Input, Progress, Space, Spin, Statistic, Typography } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import EVAPI from '../lib/ev_lib/main';
import { URLQuery } from '../util/url_queries';
import { progressColor } from '../lib/stat_progress_color';

interface WidgetProps {
  title:string,
  modulesRecordsStatusesUuid?:string,
  warehousesUuids?:string,
  destinationCountry?:string,
  destinationState?:string,
  destinationCity?:string,
  hasTitle?:'y'|'n',
  dateInFrom?:string|Date,
  dateInTo?:string|Date,
  linkedToPackingOrder?:'y'|'n',
  showLimitation?:boolean,
  limit?:number|string,
  customProgress?:number,
  imageSource?:string,
  tooltip?:string
}

export default function StorageSizeStats(props:WidgetProps) {
  const formatter = new Intl.NumberFormat('en-US');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [storageStats, setStorageStats] = useState<any>(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [gageColor, setGageColor] = useState<string|undefined>(undefined);

  const imageSource = undefined;
  const tooltipDescription = undefined;

  useEffect(() => {
    getStorageStats();
  }, []);

  const getStorageStats = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.subscription_uuid = localStorage.getItem('core_uuid');
    EV.key = 'storage_size'

    var res:any = await EV.getSubscriptionStatForKey();
    
    if(res?.data) {
      // calculate the precentage
      setStorageStats({
        ...res.data,
        current_value: res.data.current_value?.toFixed(2)
      });
        
      var percentage = res.data.current_value / res.data.limit * 100;
      setProgress(percentage);
      setGageColor(progressColor(percentage));
    }
    else {
      
    }

    setIsLoading(false);
    return;
  }
  
  return <>
    <Card>
      {(imageSource) && <div
        style={{
          background: `url(${imageSource})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '100%', height: 200
        }} 
      />}
      <Progress 
        showInfo={false} 
        status={'active'}
        percent={props.customProgress||progress}
        strokeColor={gageColor}
      />
      <Typography.Title
        level={5}
        children={props.title}
        style={{margin: 0, padding: 0}}
      />
      {tooltipDescription && <Typography.Paragraph>
        <Typography.Text type="secondary">
          {tooltipDescription}
        </Typography.Text>
      </Typography.Paragraph>}
      <Typography.Paragraph>
        <Typography.Text strong style={{fontSize: 22}}>
          {formatter.format(storageStats?.current_value)} GB
        </Typography.Text>
        <Typography.Text style={{marginLeft: 10, fontSize: 16}}>
          / {formatter.format(storageStats?.limit)} GB
        </Typography.Text>
      </Typography.Paragraph>
    </Card>
  </>
}