/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { Typography } from "antd";

export default function NotFound(props:any) {

  return <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
    <div className="not-found-container">
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src={require('../assets/lost-amico.png')} width={300} />
      </div>
      <Typography.Title level={3} children={'Record Not Found'} />
      <Typography.Text>
        Failed to load the requested record. This 
        could happen due to:
      </Typography.Text>
      
      <Typography.Text style={{marginTop: 10}}>
        <ul>
          <li>You followed a broken link.</li>
          <li>Record was deleted.</li>
          <li>Record is not enabled for access.</li>
        </ul>
      </Typography.Text>
    </div>
  </div>
}