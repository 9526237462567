/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { ExportOutlined, LockOutlined, PullRequestOutlined, SettingOutlined, TeamOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import BlankPage from "../framework/blank_page";
import { config } from "../config";
import { useEffect, useState } from "react";
import EVAPI from "../lib/ev_lib/main";
import { convertSystemSettingsArrayDatasetToObject } from "../lib/ev_lib/data_processors";
import { URLQuery } from "../util/url_queries";

function InfoCell(props:any) {
  return <Card>
    <div>
      <Typography.Text strong>{props.label}</Typography.Text>
    </div>
    <div>
      <Typography.Text>{props.data}</Typography.Text>
    </div>
  </Card>
}

export default function AboutSystem(props:any) {
  const [isLoadingCompanySettings, setIsLoadingCompanySettings] = useState<boolean>(false);
  const [coreVersion, setCoreVersion] = useState<string|undefined>('-');

  const [isLoadingDispatchSettings, setIsLoadingDispatchSettings] = useState<boolean>(false);
  const [dispatchVersion, setDispatchVersion] = useState<string|undefined>('-');

  useEffect(() => {
    getCompanySettings();
    getDispatchGlobalSettings();
  }, []);

  const getCompanySettings = async () => {
    if(isLoadingCompanySettings) return null;
    setIsLoadingCompanySettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    var res:any = await EV.getSettings();

    if(res) {
      setCoreVersion(res.version);
    }
    
    if(!res || res.code !== 200) {
      // handle error if needed
    }

    if(res.data) {
      var data = convertSystemSettingsArrayDatasetToObject(res.data);
      localStorage.setItem('company_settings', JSON.stringify(data));
    }

    setIsLoadingCompanySettings(false);
    return;
  }

  const getDispatchGlobalSettings = async () => {
    if(isLoadingDispatchSettings) return null;
    setIsLoadingDispatchSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = process.env.REACT_APP_CORE_DISPATCH_URI;
    
    var res:any = await EV.getDispatchGlobalSettings();

    if(res) {
      setDispatchVersion(res.version);
    }

    if(!res || res.code !== 200) {
      // handle error if needed
    }

    setIsLoadingDispatchSettings(false);
    return;
  }

  // call the API version
  const dataSet = [
    {
      label: 'Portal Version',
      data: config.version
    },
    {
      label: 'CORE URI',
      data: localStorage.getItem('target_domain')
    },
    {
      label: 'CORE Version',
      data: coreVersion
    },
    {
      label: 'Dispatch Version',
      data: dispatchVersion
    },
    {
      label: 'Subscription',
      data: 'No available'
    }
  ];

  return <BlankPage
    showSideMenu
    padded
  >
    <Typography.Title children={'About the System'} level={2} />
    <Typography.Paragraph>
      <Typography.Text>
        This page offers system technical details. Customer support 
        may ask you to visit thsi page to provide information that could help
        assisting you.
      </Typography.Text>
    </Typography.Paragraph>

    <Row gutter={[15, 15]}>
      {dataSet?.map((data:any, key:number) => {
        return <Col key={key} span={8} xs={24} sm={12} md={8}>
          <InfoCell
            label={data.label}
            data={data.data}
          />
        </Col>
      })}
    </Row>
  </BlankPage>
}