/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Button, Col, Divider, Form, Input, MessageArgsProps, Row, Select, Space, Table, Typography, message } from "antd";
import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import { CheckOutlined, EditFilled, FormOutlined, LeftCircleFilled, PlusCircleFilled, SearchOutlined, WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import EVAPI from '../../../lib/ev_lib/main';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../../util/url_queries';
import { userSettings } from '../../../lib/user_settings';

export default function SettingsUsersListing(props:any) {
  const navigate = useNavigate();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined);
  
  const [filters, setFilters] = useState<{
    username?:string,
    email?:string,
    type?:string
  }>({});

  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([]);

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getUsers();
    setIsReady(true);
  }, []);
  
  useEffect(() => {
    getUsers();
  }, [searchTerm, filters])

  const getUsers = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.searchTerm = searchTerm;
    EV.type = filters?.type;

    var res:any = await EV.getUsers();

    if(!res || res.code !== 200) {
      messageApi.open({
        key: 'getUsersServerError',
        type: 'error',
        content: 'Failed to load users'
      })
    }

    else if(res.code === 200) {
      setUsers(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const columns = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      fixed: 'left' as 'left',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {
      key: 'username', 
      title: 'Username', 
      width: 200,
      dataIndex: 'username'
    },
    {
      key: 'name', 
      title: 'Name', 
      width: 200,
      dataIndex: 'name',
      render: (_:any, record:any) => {
        return <Typography.Text style={{textTransform: 'capitalize'}}>
          {record.name_prefix}. {record.first_name} {record.last_name}
        </Typography.Text>
      }
    },
    {
      key: 'phone', 
      title: 'Phone', 
      width: 200,
      dataIndex: 'phone',
      render: (_:any, record:any) => {
        return <Typography.Text style={{textTransform: 'capitalize'}}>
          {record.phone_country_code} - {record.phone}
        </Typography.Text>
      }
    },
    {
      key: 'email', 
      title: 'Email', 
      width: 200,
      dataIndex: 'email'
    },
    {
      key: 'type', 
      title: 'Type', 
      width: 200,
      dataIndex: 'type',
      render:  (_:any, record:any) => {
        return <Typography.Text style={{textTransform: 'capitalize'}}>
          {record.type}
        </Typography.Text>
      }
    },
    {
      key: 'users_groups_uuid', 
      title: 'Group', 
      width: 200,
      dataIndex: 'users_groups_uuid',
      render:  (_:any, record:any) => {
        return <Typography.Text style={{textTransform: 'capitalize'}}>
          {record.users_groups_uuid}
        </Typography.Text>
      }
    },
    {
      key: 'is_blocked', 
      title: 'Blocked', 
      dataIndex: 'is_blocked',
      width: 100,
      render: (_:any, record:any) => {
        if(record.is_blocked === 'y') return <WarningFilled style={{color: 'red'}} />
        else return null
      }
    },
    {
      key: 'created_at', 
      title: 'Created', 
      dataIndex: 'created_at',
      width: 170,
      render: (_:any, record:any) => {
        return momentTz(record.created_at)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      key: 'action',
      title: 'Action', 
      fixed: 'right' as 'right',
      width: 100,
      render: (_:any, record:any) => (
        <Button
          type='link'
          children={<FormOutlined />}
          onClick={() => navigate(`/system/settings/users/form/${record.uuid}`)}
        />
      )
    },
  ];
  
  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized) && <>
        <Button
          type={'primary'}
          icon={<PlusCircleFilled />}
          children={'Add New'}
          onClick={() => navigate('/system/settings/users/form')}
        />
      </>}
    </Space>}
  >    
    {contextHolder}
    
    <Typography.Title level={1} children={'Users'} />

    <Row gutter={15}>
      <Col span={18}>
        <Input
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={6}>
        <Select
          style={{width: '100%'}}
          placeholder={'Type'}
          allowClear
          onClear={() => setFilters({...filters, type: undefined})}
          onChange={(value) => setFilters({...filters, type: value})}
          options={[
            {value: 'management', label: 'Management'},
            {value: 'exporter', label: 'Exporter'},
          ]}
        />
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      scroll={{ x: 1300 }}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={users} 
      columns={columns}
    />
  </BlankPage>
}