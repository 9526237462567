/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState } from 'react';

import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Table, Tag, Typography } from "antd";

import EVAPI from '../../../../lib/ev_lib/main';
import { URLQuery } from '../../../../util/url_queries';
import { userSettings } from '../../../../lib/user_settings';

export default function InvoicesFormItemsCargoesDrawer(props:any) {
  const [isLoadingCargoes, setIsLoadingCargoes] = useState<boolean>(false);
  const [loadingCargoesErrors, setLoadingCargoesErrors] = useState<any[]>([]);
  const [cargoesSearchTerm, setCargoesSearchTerm] = useState<string|null>(null);
  const [cargoes, setCargoes] = useState<any[]|null>(null);
  const [openCargoesDrawer, setOpenCargoesDrawer] = useState(false);
  
  const getCargoes = async () => {
    // check requirements
    setLoadingCargoesErrors([]);
    var errors:any[] = [];
    
    setIsLoadingCargoes(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = 'y';
    EV.searchTerm = cargoesSearchTerm;

    var res:any = await EV.getCargoes();
    
    if(!res) {
      setLoadingCargoesErrors([
        'Failed to download records from the server'
      ]);
    }

    else if(res.status === 'fail') {
      setLoadingCargoesErrors(res.errors);  
    }
    
    else if(res.data) {
      setCargoes(res.data);
    }

    setIsLoadingCargoes(false);
    return null;
  }

  const toggleOpenCargoesDrawer = () => {
    getCargoes();
    setOpenCargoesDrawer(!openCargoesDrawer);
  };
  
  let cargoesTableColumns = [
    {
      key: 'modules_records_statuses_label', 
      title: 'Status',
      width: 130,
      fixed: 'left' as 'left',
      render: (_:any, record:any) => <Tag
        color={record.modules_records_statuses_highlight_color}
        children={record.modules_records_statuses_label} 
      />
    },
    {
      key: 'uuid',
      title: 'UUID',
      dataIndex: 'uuid',
      width: 130,
      fixed: 'left' as 'left',
      render: (_:any, record:any) => {
        return `...${record.uuid.substr(record.uuid.length - 10)}`
      }
    },
    {
      key: 'type', 
      title: 'Type', 
      dataIndex: 'type', 
      width: 100
    },
    {
      key: 'vin_number', 
      title: 'VIN #', 
      dataIndex: 'vin_number', 
      width: 200
    },
    {
      key: 'make', 
      title: 'Make', 
      dataIndex: 'make',
      width: 140
    },
    {
      key: 'model', 
      title: 'Model', 
      dataIndex: 'model',
      width: 140
    },
    {
      key: 'year', 
      title: 'Year', 
      dataIndex: 'year',
      width: 100
    },
    {
      key: 'color', 
      title: 'Color', 
      dataIndex: 'color',
      width: 120
    },
    {
      key: 'insurance_type', 
      title: 'Insurance Type', 
      dataIndex: 'insurance_type',
      width: 140
    },
    {
      key: 'link',
      title: 'Action',
      fixed: 'right' as 'right',
      render: (_:any, record:any) => (
        <Button 
          type={'link'}
          size={'small'}
          icon={<PlusCircleFilled />}
          onClick={() => props.onLinking(record)}
        />
      )
    }
  ]

  return <>
    <Typography.Link onClick={toggleOpenCargoesDrawer}>Select from inventory</Typography.Link>
    <Drawer 
      title="Inventory Cargoes" 
      width={'80%'}
      placement="right" 
      onClose={toggleOpenCargoesDrawer} 
      open={openCargoesDrawer}
    >
      <Input 
        placeholder={"Type to search"}
        suffix={<SearchOutlined />}
        onChange={(e) => null}
      />

      <div style={{marginBottom: 20}} />

      <Table
        size={userSettings('compact_view') ? 'small' : undefined}
        scroll={{ x: 1300 }}
        columns={cargoesTableColumns}
        dataSource={cargoes||[]}
      />
    </Drawer>
  </>
}