// URL should be the {window} object
export function setClearFormFields(fields:any) {
  if(typeof fields !== 'object') {
    console.warn(
      'setClearFormFields first params should be an object received:',
      typeof fields, fields
    );

    return false;
  }

  if(Object.keys(fields).length < 1) {
    console.warn(
      'setClearFormFields fields object provided is empty',
      Object.keys(fields).length, fields
    );

    return false;
  }

  var clearableFields:string[] = [];

  Object.keys(fields).map((field:string, key:number) => {
    if(
      typeof fields[field] === 'string'
      && fields[field].length < 1
    ) {
      clearableFields.push(field)
    }
  })
  
  if(clearableFields.length > 0) return clearableFields;
  else return undefined;
}