/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { CalendarOutlined, DollarOutlined, ExportOutlined, FieldTimeOutlined, LockOutlined, PullRequestOutlined, SettingOutlined, TeamOutlined, TruckOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import BlankPage from "../../framework/blank_page";
import StorageStats from "../../widgets/StorageSizeStates";
import { useEffect, useState } from "react";
import EVAPI from "../../lib/ev_lib/main";
import WarehousesLimitStats from "../../widgets/WarehousesCount";
import UsersLimitStats from "../../widgets/usersCount";
import OnlineOptimizerLimitStats from "../../widgets/OnlineOptimizerCredit";
import VinDecoderLimitStats from "../../widgets/VinDecoderStats";
import InventoryLimitStats from "../../widgets/InventoryLimitStats";
import OutgoingEmailsLimitStats from "../../widgets/OutgoingEmailsStats";

export default function SettingsList(props:any) {
  const navigate = useNavigate();

  const [errors, setErrors] = useState<any[]|undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [subscription, setSubscription] = useState<any>(undefined);

  useEffect(() => {
    getSubscriptionDetails()
  }, []);

  // get subscription
  const getSubscriptionDetails = async () => {
    if(isLoading) return false;
    setIsLoading(true);

    var res:any = undefined;

    var EV:any = new EVAPI;
    EV.debug = process.env.NODE_MODE === 'development' ? true : false;
    EV.lang = 'en';
    EV.baseAPI = process.env.REACT_APP_CORE_DISPATCH_URI;
    EV.domain = localStorage.getItem('core_uuid');

    res =  await EV.getDomain();

    if(!res) {
      setErrors([{
        en: 'Failed to connect to the server'
      }]);
    }

    else if(res.status !== 'success') {
      setErrors(res.errors);
    }

    else {
      setSubscription(res.data);
    }

    setIsLoading(false);
    return;
  }
  
  const menuItems:any = [
    {
      key: 'general',
      icon: <SettingOutlined style={{fontSize: '16pt'}}/>,
      title: 'General',
      description: 'General description about this tab section',
      onClick: () => navigate('/system/settings/general')
    },
    {
      key: 'users-groups',
      icon: <LockOutlined style={{fontSize: '16pt'}} />,
      title: 'Groups & Permissions',
      description: 'Manage system groups and permissions',
      onClick: () => navigate('/system/settings/users-groups')
    },
    {
      key: 'users',
      icon: <TeamOutlined style={{fontSize: '16pt'}} />,
      title: 'Users',
      description: 'Users and access permission management',
      onClick: () => navigate('/system/settings/users'),
      attached: <UsersLimitStats
        title={'Users'}
        display="compact"
      />
    },
    {
      key: 'modeuls-records-statuses',
      icon: <ExportOutlined style={{fontSize: '16pt'}} />,
      title: 'Modules Statuses',
      description: 'System modules records statuses or stages',
      onClick: () => navigate('/system/settings/modules-records-statuses')
    },
    {
      key: 'warehouses',
      icon: <ExportOutlined style={{fontSize: '16pt'}} />,
      title: 'Warehouses',
      description: 'System warehouses management',
      onClick: () => navigate('/system/settings/warehouses'),
      attached: <WarehousesLimitStats
        title={'Warehouses'}
        display="compact"
      />
    },
    {
      key: 'trucking-companies',
      icon: <TruckOutlined style={{fontSize: '16pt'}} />,
      title: 'Trucking Companies',
      description: 'System trucking companies management',
      onClick: () => navigate('/trucking-companies')
    },
    {
      key: 'steam-ship-lines',
      icon: <ExportOutlined style={{fontSize: '16pt'}} />,
      title: 'Steam Ship Lines',
      description: 'System steamship lines management',
      onClick: () => navigate('/steam-ship-lines')
    },
    {
      key: 'equpiments-types',
      icon: <UnorderedListOutlined style={{fontSize: '16pt'}} />,
      title: 'Equipments Types',
      description: 'Manage all equipments types in the system',
      onClick: () => navigate('/system/settings/equipments-types')
    },
    {
      key: 'general',
      icon: <DollarOutlined style={{fontSize: '16pt'}} />,
      title: 'Accounting',
      description: 'Accounting general settings',
      onClick: () => navigate('/system/settings/accounting/general')
    },
    {
      key: 'income-deposit-accounts',
      icon: <DollarOutlined style={{fontSize: '16pt'}} />,
      title: 'Income Deposit Accounts',
      description: 'Manage income deposit accounts',
      onClick: () => navigate('/system/settings/accounting/income-deposit-accounts')
    },
    {
      key: 'drop-off-disabled-dates',
      icon: <CalendarOutlined style={{fontSize: '16pt'}} />,
      title: 'Drop Off Disabled Dates',
      description: 'Manage drop off disabled dates',
      onClick: () => navigate('/system/settings/drop-off/disabled-dates')
    },
    {
      key: 'drop-off-time-slots',
      icon: <FieldTimeOutlined style={{fontSize: '16pt'}} />,
      title: 'Drop Off Time Slots',
      description: 'Manage drop off time slots',
      onClick: () => navigate('/system/settings/drop-off/time-slots')
    },
    {
      key: 'deleted-documents',
      icon: <CalendarOutlined style={{fontSize: '16pt'}} />,
      title: 'Storage Trash Bin',
      description: 'Clean or restore deleted files and documents',
      onClick: () => navigate('/system/settings/storage/bin')
    },
  ]

  return <BlankPage
    showSideMenu
    padded
  >
    <Typography.Title children={'System Settings'} level={2}/>

    <Row gutter={[30, 30]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <StorageStats 
          title={'Storage'}
        />
      </Col>
      <Col xs={16} sm={16} md={8} lg={6} xl={6}>
        <VinDecoderLimitStats 
          title={'VIN Decoder'}
        />
      </Col>
      <Col xs={16} sm={16} md={8} lg={6} xl={6}>
        <OnlineOptimizerLimitStats 
          title={'Online Optimizer'}
        />
      </Col>
      <Col xs={16} sm={16} md={8} lg={6} xl={6}>
        <InventoryLimitStats 
          title={'Inventory'}
        />
      </Col>
      <Col xs={16} sm={16} md={8} lg={6} xl={6}>
        <OutgoingEmailsLimitStats 
          title={'Outgoing Emails'}
        />
      </Col>
    </Row>

    <Divider plain />

    <Row gutter={[30, 50]}>
      {menuItems.map((item:any, key:number) => {
        return <Col xs={16} sm={16} md={8} lg={6} xl={4}>
          <div
            style={{cursor: 'pointer'}}
            onClick={item.onClick} 
          >
            <Button
              type="default"
              icon={item.icon}
              size="large"
            />
            <div style={{marginTop: 15}}>
              <div><Typography.Text strong>{item.title}</Typography.Text></div>
              <div className={'small-font'}>{item.description}</div>
            </div>
            {item.attached}
          </div>
        </Col>
      })}
    </Row>
  </BlankPage>
}