/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { ApiFilled, CheckOutlined, CloseCircleFilled, DashOutlined, DeleteFilled, DeleteOutlined, ExpandAltOutlined, FileAddFilled, FireTwoTone, FormOutlined, LeftCircleFilled, LoadingOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, SearchOutlined, ThunderboltTwoTone } from "@ant-design/icons";
import { Alert, Button, Checkbox, Col, DatePicker, Drawer, Form, Input, message, Modal, ModalProps, Row, Segmented, Select, Space, Spin, Table, Tabs, Tag, Typography } from "antd";

import EVAPI from '../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';
import { lime, red } from '@ant-design/colors';

export default function PackingOrdersCargoes(props:any) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [openAvailableCargoesDrawer, setOpenAvailableCargoesDrawer] = useState(false);
  const [availableCargoesSearchTerm, setAvailableCargoesSearchTerm] = useState<string|null>(null);
  const [availableCargoes, setAvailableCargoes] = useState<any[]|null>(null);
  const [isLoadingAvailableCargoes, setisLoadingAvailableCargoes] = useState<boolean>(false);
  const [loadingAvailableCargoesErrors, setLoadingAvailableCargoesErrors] = useState<any[]>([]);
  
  const [cargoes, setCargoes] = useState<any[]|null>(null);
  const [showMatchingCargoes, setShowMatchingCargoes] = useState<boolean>(false);
  const [isLoadingCargoes, setisLoadingCargoes] = useState<boolean>(false);
  const [loadingCargoesErrors, setLoadingCargoesErrors] = useState<any[]>([]);

  const [isLoadingStatuses, setIsLoadingStatuses] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [cargoesStatuses, setcargoesStatuses] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string|undefined>(undefined);

  const [bulkUpdateModal, setBulkUpdateModal] = useState<ModalProps|null>(null);

  useEffect(() => {
    getCargoesStatuses()
    getPackingOrderCargoes();
  }, [props.packingOrderUuid]);

  useEffect(() => {
    getAvailableCargoes();
  }, [
    availableCargoesSearchTerm,
    showMatchingCargoes, 
    props.containerDestination
  ])

  const getCargoesStatuses = async () => {
    if(isLoadingStatuses) return null;
    setIsLoadingStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderDirection = 'ASC';
    EV.module = 'cargoes';
    EV.showAll = true;
    EV.active = 'y';

    var res:any = await EV.getModulesRecordsStatuses();
    
    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          ...item
        });
      });
    }

    setcargoesStatuses(sortedData);

    setIsLoadingStatuses(false);
    return null;
  }

  // get all available cargoe linked to the exporter with
  // the same container destination
  const getAvailableCargoes = async () => {
    // check requirements
    setLoadingAvailableCargoesErrors([]);
    var errors:any[] = [];

    var country = undefined;
    var city = undefined;

    if(showMatchingCargoes) {
      var country = props.containerDestination?.country;
      var city = props.containerDestination?.city;
    }
    
    setisLoadingAvailableCargoes(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.exportersUuid = props.exporterUuid;
    EV.destinationCountry = country;
    EV.destinationCity = city;
    EV.linkedToPackingOrder = 'n';
    EV.showAll = 'y';
    EV.searchTerm = availableCargoesSearchTerm;

    var res:any = await EV.getCargoes();
    
    if(!res) {
      setLoadingAvailableCargoesErrors([
        'Failed to download records from the server'
      ]);
    }

    else if(res.status === 'fail') {
      setLoadingAvailableCargoesErrors(res.errors);  
    }
    
    else if(res.data) {
      setAvailableCargoes(res.data);
    }

    setisLoadingAvailableCargoes(false);
    return null;
  }

  const getPackingOrderCargoes = async () => {
    // check requirements
    var errors:any[] = [];

    if(!props.packingOrderUuid) {
      errors.push('Packing order UUID is required to get linked cargoes');
    }

    if(errors.length > 0) {
      setLoadingCargoesErrors(errors);
      return null;
    }

    setisLoadingCargoes(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.packingOrdersUuid = props.packingOrderUuid;
    EV.linkedToPackingOrder = 'y';

    var res:any = await EV.getCargoes();
    
    if(!res) {
      setLoadingCargoesErrors([
        'Failed to download records from the server'
      ]);
    }

    else if(res.status === 'fail') {
      setLoadingCargoesErrors(res.errors);  
    }
    
    else if(res.data) {
      setCargoes(res.data);
    }

    setisLoadingCargoes(false);
    return null;
  }

  const linkCargo = async (cargoUuid?:string) => {
    if(!cargoUuid) {
      console.error('No cargo selected');
      return null;
    }

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = props.packingOrderUuid;
    EV.cargo_uuid = cargoUuid;

    var res:any = await EV.linkCargoToPackingOrder();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to link the cargo to the packing order',
      }); 
    }

    else if(res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      }); 
    }
    
    else if(res.status === 'success') {
      messageApi.open({
        type: 'success',
        content: 'Cargo linked successfully'
      }); 

      getAvailableCargoes();
      getPackingOrderCargoes();
    }
  }

  const unlinkCargo = async (cargoUuid?:string) => {
    if(!cargoUuid) {
      console.error('No cargo selected');
      return null;
    }

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = props.packingOrderUuid;
    EV.cargo_uuid = cargoUuid;

    var res:any = await EV.unlinkCargoFromPackingOrder();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to unlink the cargo from the packing order',
      }); 
    }

    else if(res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      }); 
    }
    
    else if(res.status === 'success') {
      messageApi.open({
        type: 'success',
        content: 'Cargo unlinked successfully'
      });

      getPackingOrderCargoes();
    }
  }

  const updateCargoes = async () => {
    // update packing orders
    if(
      isProcessing 
      || !selectedStatus 
      || !cargoes 
      || cargoes.length < 1
    ) return null;

    setIsProcessing(true);
    
    for(var i = 0; i < cargoes.length; i++) {
      var EV:any = new EVAPI;
      EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
      EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
      EV.authToken = localStorage.getItem('auth_token');
      
      EV = Object.assign(EV, {
        uuid: cargoes[i].uuid,
        modules_records_statuses_uuid: selectedStatus
      });
      
      var res:any =  await EV.updateCargo();
    }

    setIsProcessing(false);
    setBulkUpdateModal(null);
    getPackingOrderCargoes();
    return null;
  }
  
  const toggleOpenAvailableCargoesDrawer = () => {
    getAvailableCargoes();
    setOpenAvailableCargoesDrawer(!openAvailableCargoesDrawer);
  };

  const _renderScreen = () => {
    if(!props.packingOrderUuid) return <Alert 
      message="Save the record enable this section." 
      type="warning" 
    />

    else if(loadingCargoesErrors.length > 0) return loadingCargoesErrors.map((message, key) => {
      return <div style={{marginBottom: 5}} key={key}>
        <Alert
          message={message}
          type='error'
        />
      </div> 
    })

    else {
      let columns = [
        {
          key: 'modules_records_statuses_label', 
          title: 'Status',
          width: 130,
          fixed: 'left' as 'left',
          render: (_:any, record:any) => <Tag
            color={record.modules_records_statuses_highlight_color}
            children={record.modules_records_statuses_label} 
          />
        },
        {
          key: 'uuid',
          title: 'UUID',
          dataIndex: 'uuid',
          width: 130,
          fixed: 'left' as 'left',
          render: (_:any, record:any) => {
            return `...${record.uuid.substr(record.uuid.length - 10)}`
          }
        },
        {
          key: 'type', 
          title: 'Type', 
          dataIndex: 'type', 
          width: 100
        },
        {
          key: 'fuel_type', 
          title: 'Fuel', 
          dataIndex: 'fuel_type', 
          render: (_:any, record:any) => {
            if(['electric', 'hybrid'].includes(record.fuel_type)) {
              return <ThunderboltTwoTone
                twoToneColor={lime[5]}
                style={{fontSize: 22}}
              />
            }

            else if (['gasoline'].includes(record.fuel_type)) {
              return <FireTwoTone
                twoToneColor={red[4]}
                style={{fontSize: 22}}
              />
            }

            else return null;
          },
          width: 70
        },
        {
          key: 'vin_number', 
          title: 'VIN #', 
          dataIndex: 'vin_number', 
          width: 200
        },
        {
          key: 'make', 
          title: 'Make', 
          dataIndex: 'make',
          width: 140
        },
        {
          key: 'model', 
          title: 'Model', 
          dataIndex: 'model',
          render: (_:any, record:any) => {
            return <div>
              <span style={{textTransform: 'capitalize'}}>
                {record.model} 
              </span>
              <span style={{marginLeft: 10}}>
                {(record.fuel_type && ['electric','hybrid'].includes(record.fuel_type)) && <Tag 
                  color={'lime-inverse'}
                  children={record.fuel_type}
                />}
              </span>
            </div>
          },
          width: 200
        },
        {
          key: 'year', 
          title: 'Year', 
          dataIndex: 'year',
          width: 100
        },
        {
          key: 'color', 
          title: 'Color', 
          dataIndex: 'color',
          width: 120
        },
        {
          key: 'has_title', 
          title: 'Has Title', 
          width: 100,
          dataIndex: 'has_title',
          render: (_:any, record:any) => {
            if(record.has_title === 'y') return 'Yes';
            else if(record.has_title === 'n') return 'No';
            else return '-';
          }
        },
        {
          key: 'insurance_type', 
          title: 'Insurance Type', 
          dataIndex: 'insurance_type',
          width: 140
        }
      ];
      
      let selectedColumns = [
        ...columns,
        {
          key: 'unlink',
          title: 'Action',
          fixed: 'right' as 'right',
          width: 130,
          render: (_:any, record:any) => (<Space size={'small'}>
            {props.showView && <Button 
              type={'link'}
              size={'small'}
              icon={<ExpandAltOutlined />}
              onClick={() => navigate(`/inventory/cargoes/view/${record.uuid}`)}
            />}
            {props.showEdit && <Button 
              type={'link'}
              size={'small'}
              icon={<FormOutlined />}
              onClick={() => navigate(`/inventory/cargoes/form/${record.uuid}`)}
            />}
            {props.showUnlink && <Button 
              type={'link'}
              size={'small'}
              icon={<DeleteOutlined style={{color: 'red'}} />}
              onClick={() => unlinkCargo(record.uuid)}
            />}
          </Space>)
        }
      ];

      let selectionColumns = [
        ...columns,
        {
          key: 'link',
          title: 'Action',
          fixed: 'right' as 'right',
          render: (_:any, record:any) => (
            <Button 
              type={'link'}
              size={'small'}
              icon={<PlusCircleFilled />}
              onClick={() => linkCargo(record.uuid)}
            />
          )
        }
      ]

      return <>
        <Drawer 
          title="Available Cargoes" 
          width={'80%'}
          placement="right" 
          onClose={toggleOpenAvailableCargoesDrawer} 
          open={openAvailableCargoesDrawer}
        >
          {loadingAvailableCargoesErrors.map((message, key) => {
            return <div style={{marginBottom: 5}} key={key}>
              <Alert
                message={message}
                type='error'
              />
            </div>
          })}
          
          <Checkbox 
            checked={showMatchingCargoes}
            children='Show only cargoes matching container destination.'
            onChange={(e) => {
              setShowMatchingCargoes(e.target.checked);
            }}
          />
          
          <div style={{marginBottom: 5}} />

          <Input 
            placeholder={"Type to search"}
            suffix={<SearchOutlined />}
            onChange={(e) => setAvailableCargoesSearchTerm(
              e.target.value
            )}
          />
    
          <div style={{marginBottom: 20}} />
    
          <Table
            size={userSettings('compact_view') ? 'small' : undefined}
            scroll={{ x: 1300 }}
            pagination={{ showSizeChanger: true }} 
            loading={isLoadingAvailableCargoes}
            dataSource={availableCargoes||[]} 
            columns={selectionColumns} 
          />
        </Drawer>

        <Modal
          open={bulkUpdateModal?.open}
          title={'Bulk Update Status'}
          children={<>
            <Alert
              type={'warning'}
              message={'This process cannot be revesed and '
                + 'will require manual modification to each '
                + 'record to reset.'}
            />
            <Select 
              style={{
                display: 'block', 
                width: '100%', 
                marginTop: '10px',
                marginBottom: '10px'
              }}
              showSearch
              placeholder={'Select one'}
              onChange={(value) => setSelectedStatus(value)}
              options={cargoesStatuses}
              disabled={isProcessing}
            />
            {(isProcessing) && <div>
              <Spin size={'small'}  style={{marginRight: 10}} />
              <Typography.Text type={'secondary'}>Processing...</Typography.Text>
            </div>}
          </>}
          onOk={updateCargoes}
          onCancel={() => {
            setIsProcessing(false);
            setBulkUpdateModal(null);
          }}
        />

        <Table
          size={userSettings('compact_view') ? 'small' : undefined}
          scroll={{ x: 1300 }}
          columns={selectedColumns}
          dataSource={cargoes||[]}
        />

        {(props.showBulkUpdate || props.showLinkButton) && <>
          <div style={{marginBottom: 15}} />

          <Row>
            <Col span={24} style={{display: 'flex', justifyContent: 'right'}}>
              <Space>
                <Button
                  type={'default'}
                  children={'Bulk Update Status'}
                  onClick={() => setBulkUpdateModal({open: true})}
                />
                <Button
                  type={'primary'}
                  icon={<PlusCircleFilled />}
                  children={'Link New Cargo'}
                  onClick={toggleOpenAvailableCargoesDrawer}
                />
              </Space>
            </Col>
          </Row>
        </>}
      </>
    }
  }
  
  return <>
    {contextHolder}

    {(props.showTitle) && <Typography.Title level={2} children={'Cargoes'} />}
    {(props.customTitle) && props.customTitle}

    {_renderScreen()}
  </>
}