/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { grey } from "@ant-design/colors";
import { Card, Typography } from "antd";
import moment from "moment";

export default function DaySlot(props:any) {

  return <Card 
    hoverable={props.hoverable}
    style={{
      width: '180px',
      display: 'inline-block',
      marginRight: 10,
      marginBottom: 10,
      backgroundColor: (props.disabled) ? grey[1] : 'default'
    }} 
    onClick={() => {
      if(props.disabled) return null; 

      props.onClick(moment(props.day).format('YYYY-MM-DD'))
    }}
  >
    <center>
      <Typography.Title
        level={2}
        style={{
          padding: 0, margin: 0,
          color: (props.disabled) ? grey[5] : 'default'
        }}
        children={moment(props.day).format('Do')}
      />
      <Typography.Text
        style={{
          color: (props.disabled) ? grey[5] : 'default'
        }}
        children={moment(props.day).format('MMMM, YYYY')}
      />
      <Typography.Title
        level={4}
        style={{
          color: (props.disabled) ? grey[5] : 'default'
        }}
        children={moment(props.day).format('dddd')}
      />
    </center>
  </Card>
}