/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, FormOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, DatePicker, Form, Input, InputNumber, Menu, Pagination, Popconfirm, Row, Segmented, Select, Skeleton, Space, Table, Tabs, Tag, TimePicker, Typography, message } from "antd";

import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { URLQuery } from '../../../util/url_queries';
import { Dayjs } from 'dayjs';


export default function SettingsDropOffTimeSlots(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  
  const [selectedWarehouseUuid, setSelectedWarehouseUuid] = useState<string|undefined>(undefined);
  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>('sunday');

  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<any>(null);

  const [newTimeSlot, setNewTimeSlot] = useState<{time?:string|undefined, capacity?:number|undefined}>({time: undefined, capacity: undefined})
  const [newTimeSlotErrors, setNewTimeSlotErrors] = useState<any[]>([]);

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getWarehouses();
    getTimeSlots();
    setIsReady(true);
  }, []);
  
  useEffect(() => {
    setTimeSlots(undefined);
    getTimeSlots();
  }, [
    selectedDay,
    selectedWarehouseUuid
  ]);

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);
    setisLoadingWarehouses(false);
    return null;
  }

  const getTimeSlots = async () => {
    if(isLoadingTimeSlots || !selectedDay || !selectedWarehouseUuid) return null;
    setIsLoadingTimeSlots(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouseUuid = selectedWarehouseUuid;
    EV.day = selectedDay;
    EV.showAll = true;

    var res:any = await EV.getDropOffSlots();
    
    setIsLoadingTimeSlots(false);

    if(res?.data) {
      setTimeSlots(res.data);
    }
    
    return;
  }

  const addTimeSlot = async () => {
    var errors:any[] = [];

    if(!selectedDay) {
      errors.push({
        en: 'Must select a day.'
      })
    }

    if(!selectedWarehouseUuid) {
      errors.push({
        en: 'Must select a warehouse.'
      })
    }

    // > validate the input data

    if(errors.length > 0) {
      setNewTimeSlotErrors(errors);
    }

    if(isProcessing) return null;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouse_uuid = selectedWarehouseUuid;
    EV.day = selectedDay;
    EV.time = newTimeSlot?.time;
    EV.capacity = newTimeSlot?.capacity;
    EV.showAll = true;

    var res:any = await EV.addDropOffSlots();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setNewTimeSlotErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getTimeSlots();
    }
    
    setIsProcessing(res.count);
    return;
  }

  const deleteTimeSlot = async (time:String) => {
    if(isProcessing) return;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouse_uuid = selectedWarehouseUuid;
    EV.day = selectedDay;
    EV.time = time;

    var res:any = await EV.deleteDropOffSlots();
    // var res:any = false;

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Something went wrong',
      });  
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getTimeSlots();
    }

    setIsProcessing(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    showSideMenu
    padded
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized) && <>
        <Button
          type={'primary'}
          icon={<PlusCircleFilled />}
          children={'Add New'}
          onClick={() => {
            // > Show add form
          }}
        />
      </>}
    </Space>}
  >
    {contextHolder}
    
    <Typography.Title 
      style={{marginBottom: 0, paddingBottom: 0}}
      children={'Time Slots'}
    />
    
    <Typography.Paragraph>
      Use the form below to manage your appointments system scheduling.
    </Typography.Paragraph>

    <Typography.Title level={2} children={'Warehouse'} />

    <Select 
      allowClear
      style={{ width: '100%' }}
      placeholder={'Warehouses'}
      onChange={(value) => setSelectedWarehouseUuid(value)}
      options={warehouses||[]}
    />

    <Typography.Title level={2} children={'Schedule'} />

    <Tabs
      defaultValue={selectedDay}
      items={[
        { label: 'Sunday', key: 'sunday' },
        { label: 'Monday', key: 'monday' },
        { label: 'Tuesday', key: 'tuesday' },
        { label: 'Wednsday', key: 'wednesday' },
        { label: 'Thursday', key: 'thursday' },
        { label: 'Friday', key: 'friday' },
        { label: 'Saturday', key: 'saturday' }
      ]}
      onChange={(activeKey:string) => setSelectedDay(activeKey)}
    />

    {(!selectedWarehouseUuid || !selectedDay) && <>
      <Alert
        style={{marginBottom: 10}}
        showIcon
        type='warning'
        message='Select a warehouse and day to show the available time slots.'
      />
    </>}

    {(isLoadingTimeSlots) && <>
      <Skeleton active />
    </>}

    {(timeSlots?.length < 1) && <>
      <Alert
        style={{marginBottom: 10}}
        showIcon
        type='info'
        message='No time slotes available for the selection.'
      />
    </>}

    <Space>
      {(timeSlots?.length > 0) && timeSlots.map((slot:any, index:number) => {
        return <Card style={{marginTop: 10, marginRight: 10}}>
          <center>
            <Typography.Title level={4} style={{margin: 0, padding: 0}}>
              {moment(slot.time, ['hh:mm:ss']).format('hh:mm A')}
            </Typography.Title>
            <Typography.Title level={5} style={{margin: 0, padding: 0}}>
              Cap: {slot.capacity ? Number(slot.capacity) : 'Any'}
            </Typography.Title>
            <Popconfirm
              title={'Deleting Time Slot'}
              description={'Are you sure you want to delete this time slot?'}
              onConfirm={() => deleteTimeSlot(slot?.time)}
              children={<Button
                type='link'
                children='Delete'
                danger
              />}
            />
          </center>
        </Card>
      })}
    </Space>

    {(newTimeSlotErrors?.length > 0) && <>
      <Alert 
        type='error'
        style={{marginTop: 10}}
        message={'Errors submitting your data'}
        description={<ul>{newTimeSlotErrors.map((error, key) => {
          if(typeof error === 'object') {
            error = error.en
          }

          return <li>{error}</li>;
        })}</ul>}
        closable
        onClose={() => setNewTimeSlotErrors([])}
      />
    </>}

    {(selectedWarehouseUuid && selectedDay) && <>
      <Card style={{marginTop: 10, marginRight: 10}}>
        <Typography.Paragraph>
          <Typography.Text>Select the time slot to add to the selected warehouse and day.</Typography.Text>
        </Typography.Paragraph>
        <Space>
          <TimePicker
            allowClear
            use12Hours
            style={{width: '100%'}}
            format="h:mm A"
            onChange={(time:Dayjs, timeString:string|string[]) => {
              if(time) setNewTimeSlot({...newTimeSlot, time: time.format('HH:mm')})
            }}
          />
          <InputNumber
            min={1}
            placeholder='Capacity'
            onChange={(e) => {
              if(e) setNewTimeSlot({...newTimeSlot, capacity: Number(e)})
            }}
          />
          <Button
            type='primary'
            children="Add New Time Slot"
            onClick={addTimeSlot}
          />
          </Space>
      </Card>
    </>}
  </BlankPage>
}