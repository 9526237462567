/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import React, { lazy, Suspense, useEffect, useState } from "react";
import { URLQuery } from "../../util/url_queries";
import EVAPI from "../../lib/ev_lib/main";
import { convertSystemSettingsArrayDatasetToObject } from "../../lib/ev_lib/data_processors";


export default function ContainersDocs(props:any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<string|undefined>(undefined);

  useEffect(() => {
    getCompanyTemplate();
  }, []);

  // get the system set template
  const getCompanyTemplate = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      // show alert

      setTemplate('template_1');
    }

    else if(res.code === 200) {
      // continue
      var settings:any = convertSystemSettingsArrayDatasetToObject(res.data);
      setTemplate(settings.default_documents_template||'template_1');
    }

    setIsLoading(false);
    return null;
  }

  var documentType = URLQuery('type', window);

  if(!template) return <div>
    Setting up the template, please wait...
  </div>

  switch(documentType) {
    case 'bookingMasterBillOfLading':
      const BookingMasterBillOfLading = React.lazy(() => import(`./docs/templates/${template}/booking_master_bill_of_lading.tsx`));
      return <Suspense fallback={<div>Loading...</div>}>
        <BookingMasterBillOfLading />
      </Suspense>
    break;
    
    case 'masterBillOfLading':
      const MasterBillOfLading = React.lazy(() => import(`./docs/templates/${template}/master_bill_of_lading.tsx`));
      return <Suspense fallback={<div>Loading...</div>}>
        <MasterBillOfLading />
      </Suspense>
    break;

    case 'dockReceipt':
      const DocReceipt = React.lazy(() => import(`./docs/templates/${template}/doc_receipt.tsx`));
      return <Suspense fallback={<div>Loading...</div>}>
        <DocReceipt />
      </Suspense>
    break;

    case 'packingOrder':
      const PackingOrder = React.lazy(() => import(`./docs/templates/${template}/packing_order.tsx`));
      return <Suspense fallback={<div>Loading...</div>}>
        <PackingOrder />
      </Suspense>
    break;

    default:
      // show empty screen / error screen
      return <></>
    break;
  }
}