/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export const resortTable = (
  table:string,
  columnId:string,
  callback?:()=>any
) => {
  if(
    !table
    || !columnId
  ) return undefined;

  if(
    !localStorage.getItem(`pref${table}ListingOrderBy`)
    || localStorage.getItem(`pref${table}ListingOrderBy`) !== columnId
    || (
      localStorage.getItem(`pref${table}ListingOrderBy`) === columnId
      && localStorage.getItem(`pref${table}ListingOrderDirection`) !== 'asc'
    )
  ) {
    localStorage.setItem(`pref${table}ListingOrderBy`, columnId);
    localStorage.setItem(`pref${table}ListingOrderDirection`, 'asc');
  }

  else if(
    localStorage.getItem(`pref${table}ListingOrderBy`) === columnId
    && localStorage.getItem(`pref${table}ListingOrderDirection`) === 'asc'
  ) {
    localStorage.setItem(`pref${table}ListingOrderDirection`, 'desc');
  }

  if(callback) callback();

  return;
}