export const displayName = (
  namePrefix, firstName, lastName, email = undefined
) => {
  if(!firstName && !lastName) return null;

  var structuredName = null;

  if(firstName) {
    structuredName = firstName
  };

  if(lastName) {
    if(structuredName && structuredName.length > 0) structuredName += ' ';
    
    structuredName += lastName;
  }

  if(structuredName && namePrefix) {
    // update case the first leftter and add the .
    var firstChar = namePrefix.substr(0, 1);
    
    namePrefix = firstChar.toUpperCase() + namePrefix.substring(1);

    var lastChar = namePrefix.substr(namePrefix -1);

    if(lastChar !== '.') {
      namePrefix += '.';
    }
    
    structuredName = namePrefix + ' ' + structuredName
  };

  if(email) {
    if(structuredName && structuredName.length > 0) structuredName += ' ';
    
    structuredName += email;
  }

  return structuredName;
}

export const subCharts = (
  data, 
  count = 10,
  position = 'end'
) => {
  if(!data) return undefined;

  if(data.length < count) return data;

  return data.substr(data.length - count)
}