/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, FormOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Form, Input, Menu, Pagination, Popconfirm, Row, Segmented, Select, Space, Table, Tag, Typography, message } from "antd";

import BlankPage from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../../util/url_queries';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { userSettings } from '../../../lib/user_settings';


export default function SettingsDropOffDisabledDates(props:any) {
  interface AdvanceFiltersProps {
    warehouses_uuids?: string|null|undefined,
    dates_from?:string|string[],
    dates_from_obj?: any,
    dates_to?:string|string[],
    dates_to_obj?: any,
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [newDisabledDateForm] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  
  const [advanceFilters, setAdvanceFilters] = useState<AdvanceFiltersProps>({});
  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);

  const [newDisabledDateFormData, setNewDisableddateFormData] = useState<any>({});
  const [newDisabledDateErrors, setNewDisabledDateErrors] = useState<any[]>([]);

  const [resPerPage, setResPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [disabledDates, setDisabledDates] = useState<any>(null);
  const [disabledDatesCount, setDisabledDatesCount] = useState<number>(0);

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getWarehouses();
    getDisabledDates();
    setIsReady(true);
  }, []);
  
  useEffect(() => {
    getDisabledDates();
  }, [
    currentPage,
    resPerPage
  ]);

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getDisabledDates = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    setDisabledDates([]);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.resPerPage = resPerPage;
    EV.page = currentPage;
    EV.orderBy = 'drop_off_disabled_dates.created_at';
    EV.orderDirection = 'ASC';

    // add the advanced filters object to EV
    EV = Object.assign(EV, advanceFilters);

    var res:any = await EV.getDropOffDisabledDates();

    setIsLoading(false);

    if(res?.data.length > 0) {
      setDisabledDates(res.data);
    }
    
    setDisabledDatesCount(res.count);
    return;
  }

  const addDisabledDate = async () => {
    var errors:any[] = [];

    if(!newDisabledDateFormData?.date) {
      errors.push({
        en: 'Must select a date.'
      })
    }

    if(!newDisabledDateFormData?.warehouse_uuid) {
      errors.push({
        en: 'Must select a warehouse.'
      })
    }

    // > validate the input data

    if(errors.length > 0) {
      setNewDisabledDateErrors(errors);
    }

    if(isProcessing) return null;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouse_uuid = newDisabledDateFormData.warehouse_uuid;
    EV.date = newDisabledDateFormData.date;
    EV.notes = newDisabledDateFormData?.notes;

    var res:any = await EV.addDropOffDisabledDates();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setNewDisabledDateErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getDisabledDates();
      newDisabledDateForm.resetFields();
      setNewDisableddateFormData({});
      setNewDisabledDateErrors([]);
    }
    
    setIsProcessing(res.count);
    return;
  }

  const deleteDisabledDate = async (warehouseUuid:string, date:any) => {
    if(isProcessing) return;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouse_uuid = warehouseUuid;
    EV.date = date;

    var res:any = await EV.deleteDropOffDisabledDates();
    // var res:any = false;

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Something went wrong',
      });  
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getDisabledDates();
    }

    setIsProcessing(false);
    return;
  }

  const columns = [
    {
      key: 'warehouses_name', 
      title: 'Warehouse',
      width: 200,
      dataIndex: 'warehouses_name'
    },
    {
      key: 'date', 
      title: 'Date', 
      width: 200,
      dataIndex: 'date',
      render: (_:any, record:any) => {
        return momentTz(record.date)
          .tz(moment.tz.guess())
          .format('YYYY-MM-DD');
      }
    },
    {
      key: 'notes', 
      title: 'Notes', 
      width: 200,
      dataIndex: 'notes'
    },
    {
      key: 'created_at', 
      title: 'Created', 
      dataIndex: 'created_at',
      width: 170,
      render: (_:any, record:any) => {
        return momentTz(record.created_at)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      key: 'action',
      title: 'Action', 
      fixed: 'right' as 'right',
      width: 100,
      render: (_:any, record:any) => <Space size={'small'}>
        <Popconfirm
          title={'Deleting Disabled Date'}
          description={'Are you sure you want to delete this disabled date?'}
          onConfirm={() => deleteDisabledDate(
            record?.warehouses_uuid,
            moment(record.date).format('YYYY-MM-DD')
          )}
          children={<Button
            danger
            type='link'
            children='Delete'
          />}
        />
      </Space>
    },
  ];

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
    </Space>}
  >
    {contextHolder}

    <Typography.Title children={'Disabled Dates'} />
    
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        getDisabledDates();
      }}
    >
      <Row gutter={15}>
        <Col span={24}>
          <Form.Item
            name={'warehouses_uuids'}
            label={'Warehouses'}
          >
            <Select 
              mode="tags"
              allowClear
              placeholder={'Warehouses'}
              onChange={(value) => setAdvanceFilters({
                ...advanceFilters,
                warehouses_uuids: value
              })}
              options={warehouses||[]}
              tokenSeparators={[',']}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            name={'dates_from'}
            label={'Dates (from)'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={advanceFilters.dates_from_obj}
              onChange={(date, dateString) => setAdvanceFilters({
                ...advanceFilters,
                dates_from: dateString,
                dates_from_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'date_to'}
            label={'Drop Off (to)'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={advanceFilters.dates_to_obj}
              onChange={(date, dateString) => setAdvanceFilters({
                ...advanceFilters,
                dates_to: dateString,
                dates_to_obj: date
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item>
            <Space>
              <Button 
                type={"primary"} 
                htmlType={"submit"}
                loading={isLoading}
              >
                Search
              </Button>
              <Button 
                type={"default"}
                onClick={() => {
                  setAdvanceFilters({});
                  form.resetFields();
                  setTimeout(() => getDisabledDates, 500);
                }}
              >
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      scroll={{ x: 900 }}
      pagination={{
        total: disabledDatesCount||undefined,
        showSizeChanger: true,
        pageSizeOptions: [15, 50, 100, 200],
        defaultPageSize: 15,
        pageSize: resPerPage,
        onChange: (page:number, pagesSize:number) => {
          localStorage.setItem('prefDropOffDisabledDatesListingResPerPage', pagesSize.toString())
          setResPerPage(pagesSize);
          setCurrentPage(page);
        }
      }}
      loading={isLoading}
      dataSource={disabledDates} 
      columns={columns} 
    />

    <Typography.Title 
      level={2} 
      children={'Disable More Dates'} 
    />

    {(newDisabledDateErrors?.length > 0) && <>
      <Alert 
        type='error'
        style={{marginBottom: 10}}
        message={'Errors submitting your data'}
        description={<ul>{newDisabledDateErrors.map((error, key) => {
          if(typeof error === 'object') {
            error = error.en
          }

          return <li>{error}</li>;
        })}</ul>}
        closable
        onClose={() => setNewDisabledDateErrors([])}
      />
    </>}
    
    <Form 
      layout="vertical"
      form={newDisabledDateForm}
      onFinish={() => addDisabledDate()}
    >
      <Form.Item
        name={'warehouse_uuid'}
        label={'Warehouses'}
        required
      >
        <Select 
          allowClear
          placeholder={'Warehouses'}
          onChange={(value) => setNewDisableddateFormData({
            ...newDisabledDateFormData,
            warehouse_uuid: value
          })}
          options={warehouses||[]}
        />
      </Form.Item>
      <Form.Item
        name={'date'}
        label={'Dates'}
        required
      >
        <DatePicker
          style={{width: '100%'}}
          format="YYYY-MM-DD"
          minDate={dayjs(new Date().toISOString())}
          value={newDisabledDateFormData?.dates_from_obj}
          onChange={(date, dateString) => setNewDisableddateFormData({
            ...newDisabledDateFormData,
            date: dateString,
            date_obj: date
          })}
        />
      </Form.Item>
      <Form.Item
        name={'notes'}
        label={'Notes'}
      >
        <TextArea 
          rows={3}
          placeholder={'Add notes to this disbaled date'}
          onChange={(e) => setNewDisableddateFormData({
            ...newDisabledDateFormData,
            notes: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isProcessing}
          >
            Save
          </Button>
          <Button 
            type={"default"}
            onClick={() => {
              setNewDisableddateFormData({
                warehouses_uuid: undefined,
                notes: undefined
              });
              newDisabledDateForm.resetFields();
            }}
          >
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}