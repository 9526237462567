/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect, ReactElement } from 'react';
import BlankPage, { LayoutModalProps } from '../../../framework/blank_page';
import { Button, Card, Col, Form, Modal, Row, Select, Space, Tabs, Typography, message } from 'antd';
import { AuditOutlined, DeleteFilled, ExpandAltOutlined, LeftCircleFilled, MailOutlined, SendOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { URLQuery } from '../../../util/url_queries';
import EVAPI from '../../../lib/ev_lib/main';
import InvoicesFormGeneral from './form/general';
import InvoicesFormItems from './form/items';
import InvoicesFormTransactions from './form/transactions';
import { displayName } from '../../../lib/ev_lib/builders';
import SelectionStyledOption from '../../../common/SelectionStyledOption';

export default function InvoicesFormNavigation(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [mailingForm] = Form.useForm();

  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  const [invoiceUuid, setInvoiceUuid] = useState<string|undefined>(uuid);
  const [invoice, setInvoice] = useState<any>(undefined);

  const [relatedEmailingList, setRelatedEmailingList] = useState<any[]>([]);

  const [docsModal, setDocsModal] = useState<{
    open:boolean,
    children?:any[]|null,
    okText?:string,
    onOk?:any,
    onCancel?:any,
    title?:string,
    footer?:any[]
  }>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    if(invoiceUuid) { 
      getInvoice();
      return;
    }

    setTimeout(() => setIsReady(true), 1500);
  }, []);

  useEffect(() => {
    getInvoice();
  }, [invoiceUuid])

  useEffect(() => {
    getInvoiceRelatedEmailingList();
  }, [docsModal])

  const getInvoice = async () => {
    if(!uuid) return;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getInvoice();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull invoice data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });  
    }

    else if(res.data) {
      setIsReady(true);
      setInvoice(res.data);
    }

    else {
      setNotFound(true);
    }

    setIsLoading(false);
    return null;
  }

  const deleteInvoice = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = invoiceUuid;
    
    var res:any = await EV.deleteInvoice();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error deleting the record.',
      });  
    }

    else if(res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  const getInvoiceRelatedEmailingList = async () => {
    if(!uuid) return;

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getInvoiceRelatedEmailingList();
    
    if(res && res.data?.length > 0) {
      var tempArray:any[] = [];

      res.data.map((record:any, key:number) => {
        // clean up the tags
        if(record.tags) {
          record.tags = record.tags.replaceAll("[", "");
          record.tags = record.tags.replaceAll("]", "");
          record.tags = record.tags.replaceAll("\"", "");
        }
        
        var companyName = record.company_name || record.name;

        tempArray.push({
          value: record.email,
          searchable: `${companyName} ${record.first_name} ${record.last_name} ${record.email} ${record.tags} ${record.uuid}`,
          label: <SelectionStyledOption
            layout={2}
            label={record.company_name}
            tail={displayName(
              record.name_prefix,
              record.first_name,
              record.last_name
            ) + (' <' + record.email + '>') as any}
          />
        })
      });

      setRelatedEmailingList(tempArray);
    }
  }

  const emailDoc = async (type:string, replyTo:any, to:any, cc:any) => {
    // initiate the library
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.to = to;
    EV.cc = cc;
    EV.replyTo = replyTo;
    EV.uuid = invoice.uuid;
    
    // call the function by the type
    var res:any = await EV.emailInvoice();

    // handle the results
    if(res && res.status === 'success') {
      mailingForm.resetFields();

      setDocsModal({open: false});
      
      messageApi.open({
        type: 'success',
        content: 'Email has been successfully queued',
      });
    }
    else if(res.errors) {
      setLayoutModal({
        open: true,
        title: 'Oops!',
        children: [<>
          <div><Typography.Text>Errors submitting your data:</Typography.Text></div>
          <ul>{res.errors.map((error:any, key:number) => {
            if(typeof error === 'object') {
              error = error.en
            }

            return <li>{error}</li>;
          })}</ul>
        </>],
        footer: [<Button
          type={'default'}
          children={'Ok'}
          onClick={() => setLayoutModal({open: false})}
        />]
      })
    }
    else {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });
    }

    return null;
  }

  const handleDocsModal = () => {
    var replyTo:any = null;
    var to:any = null;
    var cc:any = null;

    var profileEmail = null;

    var profile:any = localStorage.getItem('profile');

    if(profile) {
      try {
        if(typeof profile === 'string') {
          profile = JSON.parse(profile);
        }
        
        profileEmail = profile.email;
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') {
          console.error(
            'Failed to parse user profile',
            e
          );
        }
      }
    }

    var replyToOptions = [
      { label: 'System Default', value: null },
      { label: `Me - ${profileEmail}`, value: profileEmail }
    ];

    var children = [
      <Form
        form={mailingForm}
        layout="horizontal"
        labelCol={{span: 4}}
        style={{minWidth: '100%'}}
      >
        <Form.Item
          label={'Reply to'} 
          name={'reply_to'}
        >
          <Select
            placeholder={'Select one'}
            showSearch
            onChange={(value) => replyTo = value}
            options={replyToOptions}
          />
          <div style={{marginTop: 10}}>
            Recommended to leave this to the default value unless
            expected to get a response from any of the receivers.
          </div>
        </Form.Item>
        <Form.Item
          label={'To'} 
          name={'to'}
          required
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => to = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
        <Form.Item
          label={'CC'} 
          name={'cc'}
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => cc = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
      </Form>
    ];

    var title = 'Email Invoice';

    setDocsModal({
      open: true,
      title: title,
      onCancel: () => setDocsModal({open: false}),
      children: children,
      footer: [
        <Button
          type='default'
          children='Cancel'
          onClick={() => setDocsModal({open: false})}
        />,
        <Button
          type='primary'
          children='Email'
          icon={<SendOutlined />}
          onClick={() => emailDoc('invoice', replyTo, to, cc)}
        />,
      ]
    })
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    notFound={notFound}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized && !notFound && invoiceUuid) && <>
        <Button
          type={"primary"}
          icon={<MailOutlined />}
          children={'Email'}
          onClick={() => handleDocsModal()}
        />
        <Button
          type={"default"}
          icon={<AuditOutlined />}
          children={'Industrial Copy'}
          onClick={() => window.open(`/accounting/invoices/invoices/view/industrial/${invoiceUuid}`, '_blank')}
        />
        <Button
          type={"default"}
          icon={<ExpandAltOutlined />}
          children={'Modern Copy'}
          onClick={() => window.open(`/accounting/invoices/invoices/view/modern/${invoiceUuid}`, '_blank')}
        />
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteInvoice();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Modal
      title={docsModal.title}
      open={docsModal?.open}
      onOk={docsModal.onOk}
      onCancel={docsModal.onCancel}
      okText={docsModal.okText}
      children={docsModal.children}
      footer={docsModal.footer}
    />

    <Typography.Title 
      level={1}
      children={(invoiceUuid) ? 'Manage Invoice' : 'New Invoice'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={invoiceUuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(invoiceUuid) && <>
      <Row gutter={[15, 15]}>
        <Col span={6}>
          <Card>
            <Typography.Title
              style={{ marginTop: 0 }}
              level={3}
              children={`${Number(invoice?.sub_total)?.toFixed(2)} USD`}
            />
            <Typography.Text
              children={'Sub Total'}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Typography.Title
              style={{ marginTop: 0 }}
              level={3}
              children={`${Number(invoice?.tax_total)?.toFixed(2)} USD`}
            />
            <Typography.Text
              children={'Tax Total'}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Typography.Title
              style={{ marginTop: 0 }}
              level={3}
              children={`${Number(invoice?.grand_total)?.toFixed(2)} USD`}
            />
            <Typography.Text
              children={'Grand Total'}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Typography.Title 
              style={{marginTop: 0}}
              level={3}
              children={`${Number(invoice?.total_paid).toFixed(2)} USD`}
            />
            <Typography.Text
              children={'Total Transactions'}
            />
          </Card>
        </Col>
      </Row>
    </>}

    <Tabs 
      items={[
        {
          key: "general",
          label: 'General',
          children: <InvoicesFormGeneral 
            refreshStates={getInvoice}
            setInvoiceUuid={(x:string) => setInvoiceUuid(x)}
          />
        },
        {
          key: "items",
          label: 'Items',
          children: <InvoicesFormItems 
            refreshStates={getInvoice}
          />
        },
        {
          key: "transacation",
          label: 'Transactions',
          children: <InvoicesFormTransactions 
            refreshStates={getInvoice}
          />
        }
      ]}
    />
  </BlankPage>
}