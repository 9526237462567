/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, DownOutlined, FormOutlined, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Menu, Pagination, Row, Segmented, Select, Space, Table, Tag, Typography } from "antd";

import BlankPage from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export default function ExportersListing(props:any) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exporters, setExporters] = useState<any>(null);
  
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [isActive, setIsActive] = useState<'y'|'n'|undefined>(undefined);

  useEffect(() => {
    getExporters();
  }, []);
  
  useEffect(() => {
    getExporters();
  }, [searchTerm, isActive]);

  const getExporters = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.searchTerm = searchTerm;
    EV.isActive = isActive;
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setIsLoading(false);

    if(res.data) {
      var data = res.data;
      var sortedData:any = [];
  
      if(data.length > 0) {
        data.map((item:any, key:number) => {
          sortedData.push({
            key: item.uuid,
            ...item
          });
        });
      }
      
      setExporters(sortedData);
    }

    return null;
  }

  const columns = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      fixed: 'left' as 'left',
      width: 45,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {
      key: 'reference-id', 
      width: 35, 
      fixed: 'left' as 'left',
      title: 'Ref #', 
      dataIndex: 'reference_id'
    },
    {
      key: 'company_name', 
      width: 100, 
      title: 'Company Name', 
      dataIndex: 'company_name',
      render: (_:any, record:any) => {
        if(record.is_active !== 'y') {
          return <>
            <Tag>Inactive</Tag>
            <Typography.Text type={'secondary'} delete>{record.company_name}</Typography.Text>
          </>
        }

        return <>
          <Typography.Text>{record.company_name}</Typography.Text>
        </>
      }
    },
    {
      key: 'gov-id-number', 
      width: 100, 
      title: 'ID Number', 
      dataIndex: 'gov_id_number'
    },
    {
      key: 'created_at', 
      title: 'Created', 
      dataIndex: 'created_at',
      width: 60,
      render: (_:any, record:any) => {
        return momentTz(record.created_at)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      key: 'action',
      title: 'Action', 
      fixed: 'right' as 'right',
      width: 30,
      render: (_:any, record:any) => <Space size={'small'}>
        <Button
          type='link'
          icon={<FormOutlined />}
          title={'Manage'}
          onClick={() => navigate(`/exporters/form/${record.uuid}`)}
        />
      </Space>
    }
  ];

  return <BlankPage
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate(`/exporters/form/`)}
      />
    </Space>}
  >
    <Typography.Title children={'Exporters'} />

    <div style={{marginBottom: 20}} />

    <Row gutter={[15, 15]}>
      <Col span={20}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={4}>
        <Select
          placeholder={'Active Status'}
          style={{width: '100%'}}
          allowClear
          options={[
            { value: 'y', label: 'Active' },
            { value: 'n', label: 'Inactive' },
          ]}
          onChange={(e) => {
            setIsActive(e)
          }}
        />
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      scroll={{ x: 1000 }}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={exporters} 
      columns={columns} 
    />
  </BlankPage>
}