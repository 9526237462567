/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { blue } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Form, Input, Progress, Space, Spin, Statistic, Typography } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import EVAPI from '../lib/ev_lib/main';
import { URLQuery } from '../util/url_queries';

interface WidgetProps {
  title:string,
  modulesRecordsStatusesUuid?:string,
  warehousesUuids?:string,
  destinationCountry?:string,
  destinationState?:string,
  destinationCity?:string,
  hasTitle?:'y'|'n',
  dateInFrom?:string|Date,
  dateInTo?:string|Date,
  linkedToPackingOrder?:'y'|'n',
  showLimitation?:boolean,
  limit?:number|string,
  customProgress?:number,
  imageSource?:string,
  tooltip?:string
}

export default function CargoesCount(props:WidgetProps) {
  let profile:any = localStorage.getItem('profile');

  try {
    profile = JSON.parse(profile);
  }
  catch(e) {
    console.error('Error parsing the user profile', e);
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cargoesCount, setCargoesCount] = useState<number>(0);

  useEffect(() => {
    getCargoes();
    
    setInterval(() => {
      getCargoes();
    }, 120000);
  }, []);

  const getCargoes = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.count = true;

    if(props.modulesRecordsStatusesUuid) EV.modulesRecordsStatusesUuid = props.modulesRecordsStatusesUuid;
    if(props.warehousesUuids) EV.warehousesUuids = props.warehousesUuids;
    if(props.destinationCountry) EV.destinationCountry = props.destinationCountry;
    if(props.destinationState) EV.destinationState = props.destinationState;
    if(props.destinationCity) EV.destinationCity = props.destinationCity;
    if(props.hasTitle) EV.hasTitle = props.hasTitle;
    if(props.dateInFrom) EV.dateInFrom = props.dateInFrom;
    if(props.dateInTo) EV.dateInTo = props.dateInTo;
    if(props.linkedToPackingOrder) EV.linkedToPackingOrder = props.linkedToPackingOrder;

    var res:any = await EV.getCargoes();
    
    if(res.data?.count) {
      setCargoesCount(Number(res.data.count)); 
    }
    else {
      
    }

    setIsLoading(false);
    return;
  }
  
  return <>
    <Card>
      <div
        style={{
          background: `url(${props.imageSource})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '100%', height: 200
        }} 
      />
      <Progress 
        showInfo={false} 
        percent={props.customProgress||0}
      />
      <Typography.Title
        level={5}
        children={props.title}
        style={{margin: 0, padding: 0}}
      />
      {props.tooltip && <Typography.Paragraph>
        <Typography.Text type="secondary">
          {props.tooltip}
        </Typography.Text>
      </Typography.Paragraph>}
      <Typography.Paragraph>
        <Typography.Text strong style={{fontSize: 22}}>{cargoesCount}</Typography.Text>
        {props.showLimitation && <>
          <Typography.Text style={{marginLeft: 10, fontSize: 16}}>
            / {props.limit||<>&infin;</>}
          </Typography.Text>
        </>}
      </Typography.Paragraph>
    </Card>
  </>
}