/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect, ReactElement } from 'react';

import { ApiFilled, CheckOutlined, CloseCircleFilled, DeleteFilled, ExportOutlined, FileAddFilled, LeftCircleFilled, LoadingOutlined, MailFilled, PlusCircleFilled, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Drawer, Empty, Form, Input, message, Modal, Row, Segmented, Select, Space, Spin, Table, Tag, Typography } from "antd";

import SelectionStyledOption from '../../../../common/SelectionStyledOption';

import BlankPage, { LayoutModalProps } from "../../../../framework/blank_page";
import EVAPI from '../../../../lib/ev_lib/main';
import { config } from '../../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { setClearFormFields } from '../../../../util/clear_empty';
import { URLQuery } from '../../../../util/url_queries';
import { convertSystemSettingsArrayDatasetToObject } from '../../../../lib/ev_lib/data_processors';
import { userSettings } from '../../../../lib/user_settings';
import { subCharts } from '../../../../lib/ev_lib/builders';

export interface InvoiceProps {
  uuid: string|undefined,
  status?: 'draft'|'issued'|'sent'|'paid'|'void',
  reference_number: string|undefined,
  exporters_uuid?:string,
  company_info?:any,
  exporter_info?:any,
  issue_date?: string|string[],
  issue_date_obj?: any,
  due_date?: string|string[],
  due_date_obj?: any,
  tax_rate?:string|number,
  notes?:string|undefined,
  sub_total?:string|number,
  tax_total?:string|number,
  grand_total?:string|number,
  remittince?:string,
  total_paid?:number|string,
  container_number?:string,
  booking_number?:string,
  clear?: string[]|undefined
}

export function RelatedPackingOrder(props:any) {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const unlinkInvoiceRelatedPackingOrder = async () => {
    if(
      isLoading 
      || !props.invoiceUuid
      || !props.packingOrder
    ) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoices_uuid = props.invoiceUuid;
    EV.packing_orders_uuid = props.packingOrder?.uuid;

    var res:any = await EV.unlinkInvoiceRelatedPackingOrder();
    
    if(
      res
      && res.status === 'success'
    ) {
      setIsVisible(false);
    }

    setIsLoading(false);
    return null;
  }

  const rowStyle = {
    borderBottom: '1px dashed #ddd',
    paddingTop: 5,
    paddingBottom: 5
  }

  if(!isVisible) return null;

  if(!props.packingOrder) return <Row gutter={[5,5]} style={rowStyle}>
    <Col>
      <Spin size='small' />
    </Col>
  </Row>

  return <Row gutter={[5,5]} style={rowStyle}>
    <Col span="4">
      <span>UUID # </span>
      <a href={`/containers/packing-orders/view/${props.packingOrder?.uuid}`} target='_blank'>
        <Typography.Text strong>{subCharts(props.packingOrder?.uuid)||'-'}</Typography.Text>
      </a>
    </Col>
    <Col span="4">
      <span>Reference # </span>
      <a href={`/containers/packing-orders/view/${props.packingOrder?.uuid}`} target='_blank'>
        <Typography.Text strong>{props.packingOrder?.reference_number||'-'}</Typography.Text>
      </a>
    </Col>
    <Col span="4">
      <span>Booking # </span>
      <a href={`/containers/packing-orders/view/${props.packingOrder?.uuid}`} target='_blank'>
        <Typography.Text strong>{props.packingOrder?.booking_number||'-'}</Typography.Text>
      </a>
    </Col>
    <Col span="5">
      <span>Container # </span>
      <a href={`/containers/packing-orders/view/${props.packingOrder?.uuid}`} target='_blank'>
        <Typography.Text strong>{props.packingOrder?.container_number||'-'}</Typography.Text>
      </a>
    </Col>
    <Col span={4}>
      Cargos: <Typography.Text strong>{props.packingOrder?.number_of_cargoes||'-'}</Typography.Text>
    </Col>
    <Col span="1" style={{display: 'flex', justifyContent: 'right'}}>
      <Button 
        size='small'
        type={"link"}
        icon={<ExportOutlined />}
        onClick={() => window.open(`/containers/packing-orders/view/${props.packingOrder?.uuid}`)}
      />
      <Button 
        shape="circle"
        size='small'
        type={"link"}
        danger
        loading={isLoading}
        icon={<CloseCircleFilled />}
        onClick={unlinkInvoiceRelatedPackingOrder}
      />
    </Col>
  </Row>
}

export default function InvoicesFormGeneral(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();

  const [form] = Form.useForm();
  
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [isProcessingSubmit, setIsProcessingSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [newInvoice, setNewInvoice] = useState<boolean>(true);

  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setisLoadingExporters] = useState<boolean>(false);

  const [isLoadingSettingsDefaultTaxRate, setIsLoadingSettingsDefaultTaxRate] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|null>(null);

  const [packingOrdersDrawerOpen, setPackingOrdersDrawerOpen] = useState<boolean>(false);
  const [isLoadingPackingOrders, setIsLoadingPackingOrders] = useState<boolean>(false);
  const [unlinkedPackingOrders, setUnlinkedPackingOrders] = useState<any[]>([]);
  const [packingOrdersSearchTerm, setPackingOrdersSearchTerm] = useState<string|null>(null);
  const [unlinkedPackingOrdersCount, setUnlinkedPackingOrdersCount] = useState<number>(0);
  const [packingOrdersPerPage, setPackingOrdersPerPage] = useState<number>(15);
  const [packingOrdersCurrentPage, setPackingOrdersCurrentPage] = useState<number>(1);

  const [linkedPackingOrders, setLinkedPackingOrders] = useState<any[]>([]);

  const [invoice, setInvoice] = useState<InvoiceProps>({
    uuid: uuid||undefined,
    reference_number: URLQuery('referenceNumber', window)||undefined
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getExporters();
    getSettingsDefaultTaxRate();

    if(uuid) getInvoice();

    setTimeout(() => { setIsReady(true) }, 1500);
  }, []);

  useEffect(() => {
    getUnlinkedPackingOrders();
  }, [packingOrdersSearchTerm]);

  useEffect(() => {
    getLinkedPackingOrders();
  }, [invoice.uuid]);

  const getSettingsDefaultTaxRate = async () => {
    if(
      isLoadingSettingsDefaultTaxRate
      || uuid
    ) return null;
    
    setIsLoadingSettingsDefaultTaxRate(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      }); 
    }

    else if(res.code === 200) {
      var data = res.data;
      
      // build the settings object {uuid, value}
      var tempSettings:any = convertSystemSettingsArrayDatasetToObject(data);
      
      if(
        newInvoice
        && tempSettings.default_tax_rate
      ) {
        setInvoice({
          ...invoice,
          tax_rate: tempSettings.default_tax_rate * 100
        });

        form.setFieldsValue({
          ...invoice,
          tax_rate: tempSettings.default_tax_rate * 100
        })
      }
      
      if(
        newInvoice
        && tempSettings.invoice_remittince
      ) {
        setInvoice({
          ...invoice,
          remittince: tempSettings.invoice_remittince
        });

        form.setFieldsValue({
          ...invoice,
          remittince: tempSettings.invoice_remittince
        })
      }
    }

    setIsLoadingSettingsDefaultTaxRate(false);
    return null;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setisLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setisLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];
    
    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            invoice.exporters_uuid
            && (item.uuid === invoice.exporters_uuid)
          )  
        ) {
          var reformattedUuid = item.uuid.substring(item.uuid.length - 5);

          sortedData.push({
            value: item.uuid,
            searchable: `${item.company_name} ${item.reference_id} ${item.uuid}`,
            label: <SelectionStyledOption
              inactive={item.is_active !== 'y'}
              label={item.company_name}
              tail={`(Ref # ${item.reference_id} | UUID # ...${reformattedUuid})`}
            />
          });
        }
      });
    }

    setExporters(sortedData);
    return;
  }

  const getInvoice = async () => {
    if(!invoice.uuid) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getInvoice();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull invoice data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      // convert the date recieved from the server to
      // from date tiem to date only format
      var data = res.data;
      
      if(data.due_date) {
        var convertedDate = momentTz(data.due_date).tz(moment.tz.guess());
        data.due_date_obj = dayjs(convertedDate.format())
        
        try {
          data.due_date = convertedDate.format();
          data.due_date = data.due_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert due_date time', data.due_date, e
          );
        }
      }
      
      if(data.issue_date) {
        var convertedDate = momentTz(data.issue_date).tz(moment.tz.guess());
        data.issue_date_obj = dayjs(convertedDate.format())
        
        try {
          data.issue_date = convertedDate.format();
          data.issue_date = data.issue_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert issue_date time', data.issue_date, e
          );
        }
      }

      if(data.tax_rate) {
        data.tax_rate = (data.tax_rate * 100).toFixed(2);
      }

      // Make sure ready is set here as well as the form will not be
      // rendered when the data is suppose to be set
      setIsReady(true);

      setInvoice(data);
      form.setFieldsValue(data);
      setNewInvoice(false);
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isProcessingSubmit) return null;
    setIsProcessingSubmit(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(!newInvoice) {
      // check clearables
      invoice.clear = setClearFormFields(invoice)||undefined;
    }
    
    EV = Object.assign(EV, invoice);

    if(EV.tax_rate) {
      EV.tax_rate = EV.tax_rate / 100;
    }

    var res:any = null;
    
    if(invoice.uuid && !newInvoice) res = await EV.updateInvoice();
    else res = await EV.createInvoice();

    if(!res || res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      var invoiceUuid = invoice.uuid;

      if(!invoiceUuid) {
        setInvoice({
          ...invoice,
          uuid: res?.data?.uuid
        });

        invoiceUuid = res?.data?.uuid;
        props.setInvoiceUuid(res?.data?.uuid);
      }

      setNewInvoice(false);
      props.refreshStates();

      navigate('/accounting/invoices/invoices/form/' + invoiceUuid, {replace: true})
    }

    setIsProcessingSubmit(false);
    return;
  }

  const getUnlinkedPackingOrders = async () => {
    if(
      isLoading 
      || !invoice.uuid
    ) return null;

    setIsLoadingPackingOrders(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.searchTerm = packingOrdersSearchTerm;
    EV.expotersUuid = invoice.exporters_uuid;

    EV.resPerPage = packingOrdersPerPage;
    EV.page = packingOrdersCurrentPage;

    var res:any = await EV.getPackingOrders();
    setIsLoadingPackingOrders(false);
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to download packing orders list',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });  
    }

    else if(res.data) {
      setUnlinkedPackingOrdersCount(res.count);
      setUnlinkedPackingOrders(res.data);
    }
  }

  const getLinkedPackingOrders = async () => {
    if(
      isLoading 
      || !invoice.uuid
    ) return null;

    setIsLoadingPackingOrders(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.searchTerm = packingOrdersSearchTerm;
    EV.invoicesUuid = invoice.uuid

    EV.resPerPage = packingOrdersPerPage;
    EV.page = packingOrdersCurrentPage;

    var res:any = await EV.getPackingOrders();
    setIsLoadingPackingOrders(false);
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to download packing orders list',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      setLinkedPackingOrders(res.data);
    }
  }

  const linkPackingOrder = async (uuid:string) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoices_uuid = invoice.uuid;
    EV.packing_orders_uuid = uuid;

    var res:any = await EV.linkInvoiceRelatedPackingOrder();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to link the selected packing order',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });  
    }

    else {
      getLinkedPackingOrders();
    }

    return;
  }

  return <>
    {contextHolder}

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={invoice}
      onFinish={processSubmit}
    >
      <Segmented 
        value={invoice?.status||'draft'}
        style={{textTransform: 'capitalize'}}
        options={[
          {value: 'draft', label: 'Draft'},
          {value: 'issued', label: 'Issued'},
          {value: 'sent', label: 'Sent'},
          {value: 'paid', label: 'Paid'},
          {value: 'void', label: 'Void'},
        ]}
        onChange={(value:any) => setInvoice({
          ...invoice,
          status: value
        })}
      />

      <div style={{marginBottom: 25}} />

      <Row gutter={[15,15]}>
        <Col span={14}>
          <Form.Item
            label={'Reference #'} 
            name={'reference_number'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setInvoice({
                ...invoice,
                reference_number: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label={'Issue Date'}
            required
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={invoice.issue_date_obj}
              onChange={(date, dateString) => setInvoice({
                ...invoice,
                issue_date: dateString,
                issue_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label={'Due Date'}
            required
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={invoice.due_date_obj}
              onChange={(date, dateString) => setInvoice({
                ...invoice,
                due_date: dateString,
                due_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        required
        label={'Exporter'} 
        name={'exporters_uuid'}
        tooltip={
          'The exporters dropdown include the Reference ID number '
          + 'and the last 5 digits of the record UUID.'
        }
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value) => setInvoice({
            ...invoice,
            exporters_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(exporters||[])}
        />
      </Form.Item>

      <Form.Item
        label={'Tax Rate (%)'} 
        name={'tax_rate'}
        required
      >
        <Input 
          placeholder={'Type here'}
          suffix="%"
          onChange={(e) => setInvoice({
            ...invoice,
            tax_rate: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item
        label={'Remittince'} 
        name={'remittince'}
      >
        <TextArea 
          rows={4}
          placeholder={'Type here populate the remittince section on the invoice'}
          onChange={(e) => setInvoice({
            ...invoice,
            remittince: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item
        label={'Notes'} 
        name={'notes'}
        tooltip={"Notes will not be included or shown on the invoice nor to non-management"}
      >
        <TextArea 
          rows={2}
          placeholder={'Type here to add notes'}
          onChange={(e) => setInvoice({
            ...invoice,
            notes: e.target.value
          })}
        />
      </Form.Item>

      <Typography.Title
        style={{marginBottom: 0}}
        level={2}
        children={'Related Packing Orders'}
      />

      <Typography.Paragraph>
        Below are the related packing order to this invoice.
      </Typography.Paragraph>
      
      <div style={{marginBottom: 20}} />

      <Drawer 
        title="Link Packing Orders to the Invoice" 
        width={'80%'}
        placement="right" 
        onClose={() => {setPackingOrdersDrawerOpen(!packingOrdersDrawerOpen)}} 
        open={packingOrdersDrawerOpen}
      >        
        <div style={{marginBottom: 5}} />

        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          onChange={(e) => setPackingOrdersSearchTerm(
            e.target.value
          )}
        />
  
        <div style={{marginBottom: 20}} />
  
        <Table
          size={userSettings('compact_view') ? 'small' : undefined}
          pagination={{
            total: unlinkedPackingOrdersCount||undefined,
            showSizeChanger: true,
            pageSizeOptions: [15, 50, 100, 200],
            defaultPageSize: 15,
            pageSize: packingOrdersPerPage,
            onChange: (page:number, pagesSize:number) => {
              setPackingOrdersPerPage(pagesSize);
              setPackingOrdersCurrentPage(page);
            }
          }}
          loading={isLoadingPackingOrders}
          dataSource={unlinkedPackingOrders||[]} 
          columns={[
            {
              key: 'uuid',
              title: 'UUID',
              dataIndex: 'uuid',
              width: 200,
              render: (_:any, record:any) => {
                return `...${record.uuid.substr(record.uuid.length - 10)}`
              }
            },
            {
              key: 'reference_number', 
              title: 'Reference #', 
              dataIndex: 'reference_number', 
              width: 200
            },
            {
              key: 'exporters_company_name', 
              title: 'Exporter', 
              dataIndex: 'exporters_company_name',
            },
            {
              key: 'booking_number', 
              title: 'Booking #', 
              dataIndex: 'booking_number',
            },
            {
              key: 'container_number', 
              title: 'Container #', 
              dataIndex: 'container_number',
            },
            {
              key: 'number_of_cargoes', 
              title: 'Cargoes', 
              dataIndex: 'number_of_cargoes',
              width: 80
            },
            {
              key: 'link',
              width: 5,
              render: (_:any, record:any) => (
                <Button 
                  type={'link'}
                  size={'small'}
                  icon={<PlusCircleFilled />}
                  onClick={() => linkPackingOrder(record.uuid)}
                />
              )
            }
          ]} 
        />
      </Drawer>

      {linkedPackingOrders?.map((record, index) => {
        return <RelatedPackingOrder
          invoiceUuid={invoice.uuid}
          packingOrder={record}
        />
      })}
      
      <div style={{marginBottom: 20}} />
      
      <Row>
        <Col span={24} style={{display: 'flex', justifyContent: 'right'}}>
          <Space>
            <Button
              type={'primary'}
              icon={<PlusCircleFilled />}
              children={'Link Packing Order'}
              onClick={() => {
                getUnlinkedPackingOrders();
                setPackingOrdersDrawerOpen(!packingOrdersDrawerOpen)
              }}
            />
          </Space>
        </Col>
      </Row>

      <div style={{marginBottom: 20}} />

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </>
}