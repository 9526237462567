/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { Alert, Button, Card, Col, Row, Space, Statistic, Typography } from "antd";
import BlankPage from "../framework/blank_page";
import { ExportOutlined } from "@ant-design/icons";
import CargoGraphPerWarehouse from "../widgets/CargoGraphPerWarehouse";
import CargoesCount from "../widgets/CargoesCount";
import PackingPlansCount from "../widgets/PackingPlansCount";
import { lime, orange, purple, yellow } from "@ant-design/colors";

import warehouseStorageImg from '../assets/warehouse_storage.png';
import createLoadingPlanIllustration from '../assets/create_loading_plan_illustration.png';
import scheduleDropOffApptIllustration from '../assets/schedule_drop_off_appt.png';
import processingCargoesIllustration from '../assets/processing_cargoes_illustration.png';
import packingContainersForklift from '../assets/packing_containers_forklift.png';
import { useNavigate } from "react-router-dom";

export default function Home(props:any) {
  const navigate = useNavigate();

  return <BlankPage
    showSideMenu
    padded
    isLoading={false}
  >
    <Typography.Title level={1} children={'Dashboard'} />

    <Row gutter={[15,15]}>
      <Col span={12}>
        <Card>
          <Row gutter={[15,15]}>
            <Col span={24}>
              <div
                style={{
                  width: '100%',
                  minWidth: 300,
                  maxWidth: 400,
                  height: '100%',
                  minHeight: 300,
                  maxHeight: 300,
                  background: `url(${scheduleDropOffApptIllustration})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain'
                }}
              />
              <Typography.Title
                style={{ 
                  margin: 0, 
                  padding: 0,
                  marginBottom: 15
                }}
                level={3}
                children={'Book a Drop Off Appointment'}
              />
              <Typography.Paragraph>
                <Typography.Text>
                  Book an appointment to drop off your cargoe at one of
                  our warehouses to start processing for storage and 
                  shipping.
                </Typography.Text>
              </Typography.Paragraph>
              
              <Space>
                <Button
                  type={"primary"}
                  onClick={() => navigate('/drop-off/appointments/form',{replace: false})}
                >
                  Book Now
                </Button>
                <Button
                  onClick={() => navigate('/drop-off/appointments',{replace: false})}
                >
                  My Appointments
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <Row gutter={[15,15]}>
            <Col span={24}>
              <div
                style={{
                  width: '100%',
                  minWidth: 300,
                  maxWidth: 400,
                  height: '100%',
                  minHeight: 300,
                  maxHeight: 300,
                  background: `url(${createLoadingPlanIllustration})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain'
                }}
              />
              <Typography.Title
                style={{ 
                  margin: 0, 
                  padding: 0,
                  marginBottom: 15
                }}
                level={3}
                children={'Create Your Packing Plan'}
              />
              <Typography.Paragraph>
                <Typography.Text>
                  Create your packing plan to be prepaird for shipping
                  by our staf. All plans will be processed as soon as 
                  possibel after permission.
                </Typography.Text>
              </Typography.Paragraph>
              
              <Space>
                <Button
                  type={"primary"}
                  onClick={() => navigate('/containers/packing-plans/form',{replace: false})}
                >
                  Create Now
                </Button>
                <Button
                  onClick={() => navigate('/containers/packing-plans',{replace: false})}
                >
                  My Packing Plans
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    
    <div style={{marginBottom: 15}} />

    <Row gutter={[15,15]}>
      <Col span={6}>
        <CargoesCount 
          title='Inventory'
          showLimitation
          customProgress={10}
          imageSource={warehouseStorageImg}
          tooltip={'Total items in the inventory'}
        />
      </Col>
      <Col span={6}>
        <CargoesCount 
          title='Pending Processing Cargoe'
          linkedToPackingOrder="n"
          showLimitation
          customProgress={30}
          imageSource={processingCargoesIllustration}
          tooltip={'Inventory items not in a packing order'}
        />
      </Col>
      <Col span={6}>
        <PackingPlansCount 
          title='Submitted Packing Plans'
          unprocessed={'y'}
          showLimitation
          customProgress={30}
          imageSource={packingContainersForklift}
          tooltip={'Packing plans requiring attention'}
        />
      </Col>
      <Col span={6}>
      </Col>
    </Row>
    
    <div style={{marginBottom: 15}} />

    <Row gutter={[15,15]}>
      <Col span={24}>
        <CargoGraphPerWarehouse
          title={'Pending Processing Cargoes \\ Warehouses'}
          linkedToPackingOrder={'n'}
        />
      </Col>
    </Row>
  </BlankPage>
}