/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { blue } from '@ant-design/colors';
import { CaretDownFilled, CaretUpFilled, UserOutlined } from '@ant-design/icons';

export interface TableColumnSortIconProps {
  table:string
  columnId:string
}

export default function TableColumnSortIcon(props:TableColumnSortIconProps) {
  var orderBy:string|null|undefined = localStorage.getItem(`${props.table}By`);
  var orderDirection:string|null|undefined = localStorage.getItem(`${props.table}Direction`);
  
  if(props.columnId === orderBy) {
    if(orderDirection === 'desc') {
      return <CaretDownFilled style={{color: blue[4]}} />
    }

    else if (orderDirection === 'asc') {
      return <CaretUpFilled style={{color: blue[4]}} />
    }

    else return null;
  }

  else return null;
}