/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { gray } from '@ant-design/colors';

export interface SelectionStyledOption {
  layout?:number,
  inactive?:boolean,
  label:string,
  tail?:string
}

export default function SelectionStyledOption(props:SelectionStyledOption) {
  var layout = props.layout || 1;

  switch(layout) {
    case 2:
      return <div style={{
        borderBottomWidth: 1,
        borderBottomStyle: 'dashed',
        borderBottomColor: gray[1]
      }}>
        <div>
          {(props.inactive) && <span
            style={{marginRight: 5, color: gray[2]}}
          >
            [Inactive]
          </span>}
          <span>
            {props.label}
          </span>
        </div>
        <div>
          <span style={{color: gray[2]}}>
            {props.tail}
          </span>
        </div>
      </div>

    case 1:
    default: 
      return <div>
        {(props.inactive) && <span
          style={{marginRight: 5, color: gray[2]}}
        >
          [Inactive]
        </span>}
        <span>
          {props.label}
        </span>
        <span style={{marginLeft: 5, color: gray[2]}}>
          {props.tail}
        </span>
      </div>
  }
  
}