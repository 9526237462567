/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { FormOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Drawer, Form, Input, Menu, message, Modal, Pagination, Row, Segmented, Select, Space, Switch, Table, Tag, Typography } from "antd";

import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export default function ContactsListing(props:any) {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  
  const [openAllContactsDrawer, setOpenAllContactsDrawer] = useState(false);
  const [allContactsSearchTerm, setAllContactsSearchTerm] = useState<string|null>(null);
  const [isLoadingAllContacts, setIsLoadingAllContacts] = useState<boolean>(false);
  const [allContacts, setAllContacts] = useState<any>(props.customSelectionContacts||null);

  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<any>(null);

  useEffect(() => {
    getContacts();
  }, [])

  useEffect(() => {
    getContacts();
  }, [searchTerm])

  useEffect(() => {
    getAllContacts();
  }, [allContactsSearchTerm])

  const getContacts = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = props.type;
    EV.typeUuid = props.typeUuid;
    EV.orderBy = 'first_name';
    EV.orderDirection = 'ASC';
    EV.searchTerm = searchTerm;
    EV.showAll = true;

    var res:any = await EV.getContacts();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setContacts(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const getAllContacts = async () => {
    if(isLoadingAllContacts || props.customSelectionContacts) return null;
    setIsLoadingAllContacts(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.orderBy = 'first_name';
    EV.orderDirection = 'ASC';
    EV.searchTerm = allContactsSearchTerm;

    var res:any = await EV.getContacts();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setAllContacts(res.data);
    }

    setIsLoadingAllContacts(false);
    return null;
  }

  const unlinkContact = async (contactUuid:string) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.uuid = props.typeUuid;
    EV.contact_uuid = contactUuid;

    var res:any = null;
    
    switch(props.type) {
      case 'exporters':
        res = await EV.unlinkContactFromExporter();
      break;

      case 'exporters-consignees':
        res = await EV.unlinkContactFromExporterAsConsignee();
      break;

      case 'exporters-notify-parties':
        res = await EV.unlinkContactFromExporterAsNotifyParty();
      break;

      case 'exporters-forwarding-agents':
        res = await EV.unlinkContactFromExporterAsForwardingAgent();
      break;

      case 'intermediate-consignees':
        res = await EV.unlinkContactFromIntermediateConsignee();
      break;

      case 'trucking-companies':
        res = await EV.unlinkContactFromTruckingCompany();
      break;

      case 'steam-ship-lines':
        res = await EV.unlinkContactFromSteamShipLine();
      break;

      default:
        res = { code: 400 }
      break;
    }
    
    setIsLoading(false);
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });
    }

    else if(res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: (res.errors && res.errors.length > 0) 
          ? res.errors[0]?.en 
          : 'Server error' 
      });
    }

    else {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getContacts();
    }

    return null;
  }

  const linkContact = async (contactUuid:string) => {
    if(isLoadingAllContacts) return null;
    setIsLoadingAllContacts(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.uuid = props.typeUuid;
    EV.contact_uuid = contactUuid;

    var res:any = null;
    
    switch(props.type) {
      case 'exporters':
        res = await EV.linkContactToExporter();
      break;

      case 'exporters-consignees':
        res = await EV.linkContactToExporterAsConsignee();
      break;

      case 'exporters-notify-parties':
        res = await EV.linkContactToExporterAsNotifyParty();
      break;

      case 'exporters-forwarding-agents':
        res = await EV.linkContactToExporterAsForwardingAgent();
      break;

      case 'intermediate-consignees':
        res = await EV.linkContactToIntermediateConsignee();
      break;

      case 'trucking-companies':
        res = await EV.linkContactToTruckingCompany();
      break;

      case 'steam-ship-lines':
        res = await EV.linkContactToSteamShipLine();
      break;

      default:
        res = { code: 400 }
      break;
    }
    
    setIsLoadingAllContacts(false);
    
    if(!res || res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getContacts();
    }

    return null;
  }

  /**
   * ALL CONTACTS TABLE STRUCTURE
   */

  const allContactsColumns:any = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {
      key: 'name',
      title: 'Full Name',
      render: (_:any, record:any) => {
        return <>
          <div><Typography.Text strong>{record.company_name}</Typography.Text></div>
          <div>{record.name_prefix?.toUpperCase()}. {record.first_name} {record.last_name}</div>
        </>
      }
    },
    {
      key: 'phone', 
      title: 'Phone', 
      dataIndex: 'phone',
      render: (_:any, record:any) => {
        return <>({record.country_code}) {record.phone}</>
      }
    },
    {key: 'email', title: 'Email', dataIndex: 'email'},
    {
      key: 'tags',
      title: 'Tags',
      render: (_:any, record:any) => {
        var render:any[] =[];
        var tags = record.tags;
        
        if(!tags || tags.length < 1) {
          return null;
        }
        
        if(typeof tags === 'string') {
          try {
            tags = JSON.parse(tags);
          }
          catch(e) {
            if(process.env.NODE_MODE === 'development') console.error(
              'Failed to parse tags', e
            );

            return null;
          }
        }

        tags.map((tag:string, key:number) => {
          render.push(<Tag key={'tags-' + key} children={tag} />)
        });

        return <Space 
          key={'tags-' + record.uuid}
          wrap
          size={'small'}
        >
          {render}
        </Space>
      }
    },
    {key: 'country', title: 'Country', dataIndex: 'country'}
  ];

  if(
    props.showLinking
    && props.type
    && props.typeUuid
  ) {
    allContactsColumns.push({
      key: 'linking',
      title: 'Linking',
      render: (_:any, record:any) => (
        <Button 
          type={'link'}
          size={'small'}
          children={'Link'}
          onClick={() => linkContact(record.uuid)}
        />
      )
    });
  }

  /**
   * DIRECT CONTACTS TABLE STRUCTURE
   */

  const columns:any = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {
      key: 'name',
      title: 'Full Name',
      render: (_:any, record:any) => {
        return <>
          <div>{
            (record.company_name) 
            ? <Typography.Text strong>{record.company_name}</Typography.Text> 
            : <Typography.Text type={'secondary'} italic>Copmany not set</Typography.Text>
          }</div>
          <div style={{textTransform: 'capitalize'}}>{record.name_prefix}. {record.first_name} {record.last_name}</div>
        </>
      }
    },
    {
      key: 'phone', 
      title: 'Phone', 
      dataIndex: 'phone',
      render: (_:any, record:any) => {
        return <>({record.phone_country_code}) {record.phone}</>
      }
    },
    {key: 'email', title: 'Email', dataIndex: 'email'},
    {key: 'country', title: 'Country', dataIndex: 'country'}
  ];

  if(props.showTags) {
    columns.push({
      key: 'tags',
      title: 'Tags',
      render: (_:any, record:any) => {
        var render:any[] =[];
        var tags = record.tags;
        
        if(!tags || tags.length < 1) {
          return null;
        }
        
        if(typeof tags === 'string') {
          try {
            tags = JSON.parse(tags);
          }
          catch(e) {
            if(process.env.NODE_MODE === 'development') console.error(
              'Failed to parse tags', e
            );

            return null;
          }
        }

        tags.map((tag:string, key:number) => {
          render.push(<Tag key={'tags-' + key} children={tag} />)
        });

        return <Space 
          key={'tags-' + record.uuid}
          wrap
          size={'small'}
        >
          {render}
        </Space>
      }
    });
  }

  if(props.additionalColumns) {
    columns.push(...props.additionalColumns);
  }

  if(
    props.showLinking
    && props.type
    && props.typeUuid
  ) {
    columns.push({
      key: 'linking',
      title: 'Linking',
      render: (_:any, record:any) => (
        <Button 
          danger
          type={'link'}
          size={'small'}
          children={'Unlink'}
          onClick={() => unlinkContact(record.uuid)}
        />
      )
    });
  }

  if(props.showView) {
    columns.push({
      key: 'action',
      title: 'Action',
      render: (_:any, record:any) => <Space size={'small'}>
        <Button
          type='link'
          icon={<FormOutlined />}
          title={'Manage'}
          onClick={() => navigate(`/address-book/form/${record.uuid}`)}
        />
      </Space>
    });
  }

  /**
   * END OF CONTACTS TABLES STRUCTURE
   */

  const toggleOpenAllContactsDrawer = () => {
    getAllContacts();
    setOpenAllContactsDrawer(!openAllContactsDrawer);
  };

  return <>
    {contextHolder}

    <Drawer 
      title="Basic Drawer" 
      width={'80%'}
      placement="right" 
      onClose={toggleOpenAllContactsDrawer} 
      open={openAllContactsDrawer}
    >

      <Input 
        placeholder={"Type to search"}
        suffix={<SearchOutlined />}
        onChange={(e) => setAllContactsSearchTerm(
          e.target.value
        )}
      />

      <div style={{marginBottom: 20}} />

      <Table
        size={userSettings('compact_view') ? 'small' : undefined}
        pagination={{ showSizeChanger: true }} 
        loading={isLoadingAllContacts}
        dataSource={allContacts} 
        columns={allContactsColumns} 
      />
    </Drawer>

    <Input 
      placeholder={"Type to search"}
      suffix={<SearchOutlined />}
      onChange={(e) => setSearchTerm(
        e.target.value
      )}
    />

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={contacts} 
      columns={columns} 
    />

    <div style={{marginBottom: 20}} />

    <Row>
      <Col span={24} style={{display: 'flex', justifyContent: 'right'}}>
        <Space>
          {(props.showLinking) && <Button
            type={'primary'}
            icon={<PlusCircleFilled />}
            children={props.linkButtonLabel||'Link New Contact'}
            onClick={toggleOpenAllContactsDrawer}
          />}
        </Space>
      </Col>
    </Row>
  </>
}