/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Button, Col, Divider, Form, Input, MessageArgsProps, Row, Select, Space, Typography, message } from "antd";
import { LeftCircleFilled, WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import BlankPage, { LayoutModalProps } from "../../../../framework/blank_page";
import EVAPI from '../../../../lib/ev_lib/main';
import { convertSystemSettingsArrayDatasetToObject } from '../../../../lib/ev_lib/data_processors';
import { setClearFormFields } from '../../../../util/clear_empty';
import { URLQuery } from '../../../../util/url_queries';
import TextArea from 'antd/es/input/TextArea';

export default function SettingsAccountingGeneral(props:any) {
  const navigate = useNavigate();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
  const [isUpdatingSettings, setIsUpdatingSettings] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined)

  const [settings, setSettings] = useState<any>(undefined);

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getSettings();
    setIsReady(true);
  }, []);

  const getSettings = async () => {
    if(isLoadingSettings) return null;
    setIsLoadingSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    EV = Object.assign(EV, settings)
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      setLayoutModal({
        open: true,
        title: 'Oops!',
        children: [<>
          <Typography.Paragraph>Failed to load the system settings.</Typography.Paragraph>
        </>],
        okText: 'Try Again',
        onOk: getSettings,
        cancelText: 'Go back',
        onCancel: () => {
          setLayoutModal({open: false});
          navigate(-1)
        }
      })
    }

    else if(res.code === 200) {
      var data = res.data;

      // build the settings object {uuid, value}
      var tempSettings:any = convertSystemSettingsArrayDatasetToObject(data);
      setSettings(tempSettings);
    }

    setIsLoadingSettings(false);
    return null;
  }
  
  const updateSettings = async () => {
    if(isUpdatingSettings) return null;
    setIsUpdatingSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.body = settings;
    
    settings.clear = setClearFormFields(settings)||undefined;
    
    var res:any = await EV.updateSettings();

    if(!res || res.code !== 200) {
      messageApi.open({
        key: 'updateSettingsError',
        type: 'error',
        content: 'Failed to update system settings'
      })
    }

    else if(res.code === 200) {
      messageApi.open({
        key: 'updateSettingsSuccess',
        type: 'success',
        content: 'System settings has been updated'
      })
    }

    setIsUpdatingSettings(false);
    return null;
  }
  
  return <BlankPage
    isLoading={!isReady || isLoadingSettings}
    unauthorized={!authorized}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
    </Space>}
  >    
    {contextHolder}

    <Typography.Title level={1} children={'Accounting Settings'} />

    <Alert
      type={'warning'}
      showIcon
      message={'Save will update all data on the screen.'}
    />

    <Form
      form={form}
      layout="vertical"
      initialValues={settings}
      onFinish={updateSettings}
    >
      <Typography.Title level={2} children={'Invoices'} />

      <Form.Item
        label={'Invoice Remmitince'} 
        name={'invoice_remittince'}
      >
        <TextArea 
          rows={4}
          placeholder={'Type here to update content'}
          onChange={(e) => setSettings({
            ...settings,
            invoice_remittince: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item
        label={'Default Tax Rate'} 
        name={'default_tax_rate'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            default_tax_rate: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isUpdatingSettings}
          >
            Save
          </Button>
        </Space>
      </Form.Item>


    </Form>
  </BlankPage>
}