/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Pagination, Row, Segmented, Select, Space, Switch, Table, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import { filterGeoArray } from '../../../lib/cities';
import { setClearFormFields } from '../../../util/clear_empty';
import { URLQuery } from '../../../util/url_queries';

export default function SettingsWarehousesForm(props:any) {
  interface warehouse {
    uuid: string|null,
    name: string|null,
    country_code?: string,
    phone?: string|number,
    email?: string,
    address_1?: string,
    address_2?: string,
    city?: string,
    state?: string,
    country?: string,
    zip_code?: string|number,
    is_active?: 'y'|'n'|null,
    clear?:string[]|undefined
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|null>(null);

  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [cities, setCities] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [states, setStates] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [countries, setCountries] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [warehouse, setWarehouse] = useState<warehouse>({
    uuid: uuid||null,
    name: null,
    country_code: '+1'
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getCities();
    getStates();
    getCountries();

    if(uuid) getWarehouse();

    setIsReady(true);
  }, []);

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, 
          label: item.name,
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getWarehouse = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getWarehouse();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setWarehouse(res.data);
      form.setFieldsValue(res.data)
    }

    setIsLoading(false);
    return null;
  }

  const processWarehouse = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(warehouse.uuid) {
      // check clearables
      warehouse.clear = setClearFormFields(warehouse)||undefined;
    }

    Object.assign(EV, warehouse);

    var res:any = null;

    if(warehouse.uuid) res = await EV.updateWarehouse();
    else res = await EV.createWarehouse();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if (res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!warehouse.uuid) {
        setWarehouse({
          ...warehouse,
          uuid: res.data.uuid
        });

        navigate('/system/settings/warehouses/form/' + res.data.uuid, { replace: true });
      }
    }

    setIsLoading(false);
    return null;
  }

  const deleteWarehouse = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = warehouse?.uuid;
    
    var res:any = await EV.deleteWarehouse();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(warehouse.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteWarehouse();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(warehouse?.uuid) ? 'Manage Warehouse' : 'New Warehouse'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={warehouse?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        uuid: uuid||warehouse.uuid,
        name: warehouse.name,
        country_code: warehouse.country_code,
        phone: warehouse.phone,
        email: warehouse.email,
        address_1: warehouse.address_1,
        address_2: warehouse.address_2,
        city: warehouse.city,
        state: warehouse.state,
        country: warehouse.country,
        is_active: warehouse.is_active === 'y' ? true : false
      }}
      onFinish={processWarehouse}
    >
      <Form.Item
        label={'Name'} 
        name={'name'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          defaultValue={'123'}
          onChange={(e) => setWarehouse({
            ...warehouse,
            name: e.target.value
          })}
        />
      </Form.Item>

      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            label={'Phone'} 
            name={'phone'}
          >
            <Input 
              addonBefore={<Select 
                style={{width: 100}} 
                value={warehouse.country_code||'+1'}
                onChange={(value) => setWarehouse({
                  ...warehouse, 
                  country_code: value
                })}
              >
                <Select.Option value="+1">+1</Select.Option>
                <Select.Option value="+44">+44</Select.Option>
              </Select>}
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                phone: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Email'} 
            name={'email'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                email: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col span={16}>
          <Form.Item
            label={'Street Address'} 
            name={'address_1'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                address_1: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Unit #'} 
            name={'address_2'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setWarehouse({
                ...warehouse,
                address_2: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={'Country'} 
        name={'country'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setWarehouse({
            ...warehouse,
            country: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={countries}
        />
      </Form.Item>
      
      <Form.Item
        label={'State'} 
        name={'state'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setWarehouse({
            ...warehouse,
            state: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={filterGeoArray(
            'state',
            states||[],
            undefined,
            warehouse.country
          )}
        />
      </Form.Item>
      
      <Form.Item
        label={'City'} 
        name={'city'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setWarehouse({
            ...warehouse,
            city: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={filterGeoArray(
            'city',
            cities||[],
            warehouse.state,
            warehouse.country
          )}
        />
      </Form.Item>

      <Form.Item
        label={'Zip Code'} 
        name={'zip_code'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setWarehouse({
            ...warehouse,
            zip_code: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Switch 
          checked={warehouse.is_active === 'y' ? true : false} 
          onChange={(e) => setWarehouse({
            ...warehouse,
            is_active: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Active Status
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}