/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { Alert, Button, Form, Input, Skeleton, Typography } from 'antd';

import jsPDF from 'jspdf';
import { URLQuery } from '../../util/url_queries';

export default function ExportDropOffAppointmentsListing(props:any) {  
  const searchTerm = URLQuery('searchTerm', window);
  const modules_records_statuses_uuid = URLQuery('modules_records_statuses_uuid', window);
  const warehouses_uuids = URLQuery('warehouses_uuid', window);
  const exporters_uuid = URLQuery('exporters_uuid', window);
  const dropOffAtFrom = URLQuery('drop_off_at_from', window);
  const dropOffAtTo = URLQuery('drop_off_at_to', window);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recordsCount, setRecordsCount] = useState<number>(0);
  const [records, setRecords] = useState<any>(null);

  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    DropOffsAppointmentsListing();
  }, []);

  useEffect(() => {
    updateDocumentMeta();
  }, records);

  const DropOffsAppointmentsListing = async (count:boolean = false) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    EV.modules_records_statuses_uuid = modules_records_statuses_uuid;
    EV.searchTerm = searchTerm;
    EV.warehouse_uuid = warehouses_uuids||undefined;
    EV.exporters_uuid = exporters_uuid||undefined;
    EV.drop_off_at_from = dropOffAtFrom||undefined;
    EV.drop_off_at_to = dropOffAtTo||undefined;
    EV.showAll = true;

    EV.orderBy = localStorage.getItem('prefDropOffAppointmentsListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefDropOffAppointmentsListingOrderDirection');

    var res:any = await EV.getDropOffAppointments();
    
    if(res.data) { 
      setRecordsCount(Number(res.data.count));
      setRecords(res.data);
    }
    
    return;
  }

  const updateDocumentMeta = () => {
    document.title = 'Drop Off Appointments List';
  }

  const _renderDate = (date:any) => {
    try {
      var processedDate = moment(date);
      
      return processedDate.format('YYYY/MM/DD');
    }
    catch(e) {
      // console the error
      return '//** Err'
    }
  }

  if(!records || isLoading) return <div>
    <Skeleton />
  </div>
  
  else if(errors && errors.length > 0) return <div style={{
    width: 400,
    marginTop: 20, 
    flexDirection: 'column'
  }} >
    <Typography.Title level={2}>Oops!</Typography.Title>

    <Alert
      message={errors?.map((error:any, key:number) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <div>{error}</div>;
      })}
      style={{marginBottom: 20}}
      type='error'
    />

    <Button 
      type={"primary"}
      loading={isLoading}
      onClick={() => window.close()}
    >
      Close and Try Again
    </Button>
  </div>

  return <div className='doc-page'>
    <div className={'document-action-bar execlude-print'}>
      <button className={'document-button'} onClick={() => {
        var doc = new jsPDF("landscape", "mm", [1400, 1000]);
        var pdfjs:any= document.querySelector('#element-to-print');

        doc.html(
          pdfjs, {
            callback: function(doc) {
              doc.save(`cargoes_list_${Date.now()}.pdf`);
            },
            x: 12,
            y: 12,
            margin: 15
          }
        );    
      }}>
        Save as PDF
      </button>
    </div>
    <div id={'element-to-print'} style={{flex: 1, flexDirection: 'column', padding: 10}}>
      <div style={{marginBottom: 20}}>
        <h2 style={{padding: 0}}>Drop Off Appointments List</h2>
        <div>{recordsCount} results found</div>
      </div>
      <table style={{width: '1330px'}} className={'docDataTable'} cellSpacing={0}>
        <thead>
          <tr>
            <th>UUID #</th>
            <th>Status</th>
            <th>Warehouse</th>
            <th>Drop Off At</th>
            <th>Exporter</th>
            <th>Trucking Company</th>
            <th>Driver's Name</th>
            <th>Contact Phone</th>
            <th>Contact Email</th>
          </tr>
        </thead>
        <tbody>
          {records?.map((appt:any, index:number) => {
            var rowBackgroundColor = '#fff';
            
            if(index % 2 > 0) {
              rowBackgroundColor = '#f9f9f9';
            }

            return <tr style={{backgroundColor: rowBackgroundColor}}>
              <td width={"90px"}>{appt.uuid.substr(appt.uuid.length - 10)}</td>
              <td width={"150px"}>{appt.modules_records_statuses_label}</td>
              <td width={"300px"}>{appt.warehouses_name}</td>
              <td width={"100px"}>{_renderDate(appt.drop_off_at)}</td>
              <td width={"300px"}>{appt.exporters_company_name}</td>
              <td width={"300px"}>{appt.trucking_company_name}</td>
              <td width={"200px"}>{appt.driver_full_name}</td>
              <td width={"175px"}>{appt.contact_phone}</td>
              <td width={"175px"}>{appt.contact_email}</td>
            </tr>
          })}
        </tbody>
      </table>
      <div style={{padding: '10px 0px', marginTop: 20, borderTop: '1px dashed #aaa'}}>
        <h4 style={{margin: '3px 0'}}>Filters:</h4> 
        <p style={{margin: '3px 0'}}>Following are the fitlers used to generate the list above:</p>
        {searchTerm && <div>- Search Term: {searchTerm}</div>}
        {warehouses_uuids && <div>- Warehouses UUIDs: {warehouses_uuids}</div>}
        {exporters_uuid && <div>- Exporter UUID: {exporters_uuid}</div>}
        {modules_records_statuses_uuid && <div>- Status: {modules_records_statuses_uuid}</div>}
        {dropOffAtFrom && <div>- Drop Off At (From): {dropOffAtFrom}</div>}
        {dropOffAtTo && <div>- Drop Off At (To): {dropOffAtTo}</div>}
      </div>

      <div style={{borderTop: '1px dashed #aaa'}}>
        <p style={{fontSize: '9pt'}}>Generated using EVessel - <a href="https://evessel.io/" target="_blank">https://evessel.io</a></p>
      </div>
    </div>
  </div>
}