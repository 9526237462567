/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Button, Col, Divider, Form, Input, MessageArgsProps, Row, Select, Space, Switch, Table, Typography, message } from "antd";
import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import { CheckOutlined, DeleteFilled, EditFilled, FormOutlined, LeftCircleFilled, PlusCircleFilled, SearchOutlined, WarningFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import EVAPI from '../../../lib/ev_lib/main';
import { setClearFormFields } from '../../../util/clear_empty';
import { URLQuery } from '../../../util/url_queries';

export interface UserGroupProps {
  uuid:string|undefined,
  name:string|undefined,
  permissions?: any[],
  clear?:string[]|undefined
}

export interface PermissionProps {
  uuid?:string|undefined,
  label?:string|undefined,
  value?:boolean|undefined,
  onChange?:any,
}

export function PermissionToggle(props:PermissionProps) {
  return <div style={{marginBottom: 10}}>
    <Switch 
      checked={props.value} 
      onChange={props.onChange}
    />
    <span style={{marginLeft: 15}}>
      {props.label}
    </span>
  </div>
}

export default function SettingsUsersGroupsForm(props:any) {
  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined);

  const [newUserGroup, setNewUserGroup] = useState<boolean>(true);

  const [userGroup, setUserGroup] = useState<UserGroupProps>({
    uuid: uuid||undefined,
    name: undefined
  })

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    if(uuid) getUserGroup();
    setIsReady(true);
  }, []);

  const getUserGroup = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = userGroup.uuid;

    var res:any = await EV.getUserGroup();
    
    if(!res) {
      setLayoutModal({
        key: 'getUserGroupServerError',
        open: true,
        title: 'Server Error',
        children: [<>
          <Typography.Text>
            Server error to load the user group try again or 
            go back and select a different gruop.
          </Typography.Text>
        </>],
        okText: 'Try Again',
        onOk: () => {
          getUserGroup();
          setLayoutModal({open: false});
        },
        onCancel: () => navigate(-1)
      })
    }

    if(res.status === 'fail') {
      messageApi.open({
        key: 'getUserGroupFail',
        type: 'error',
        content: res?.errors[0]?.en,
      });

      if(res.code === 404) {
        setNotFound(true);
      }
    }

    else if(res.data) {
      setUserGroup(res.data);
      form.setFieldsValue(res.data);
      setNewUserGroup(false);
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    // handle cleared fields
    if(!newUserGroup) {
      // check clearables
      userGroup.clear = setClearFormFields(userGroup)||undefined;
    }
    
    EV = Object.assign(EV, userGroup);

    var res:any = null;

    if(userGroup.uuid && !newUserGroup) res = await EV.updateUserGroup();
    else res = await EV.createUserGroup();

    if(!res) {
      messageApi.open({
        key: 'processUserGroupFail',
        type: 'error',
        content: 'Failed to connect to server'
      });
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        key: 'processUserGroupSuccess',
        type: 'success',
        content: 'Process completed'
      });

      if(!userGroup.uuid) {
        setUserGroup({
          ...userGroup,
          uuid: res.data.uuid
        });

        navigate('/system/settings/users-groups/form/' + res.data.uuid, { replace: true });
      }

      setNewUserGroup(false);
    }

    setIsProcessing(false);
    return null;
  }

  const getPermissionValue = (permissionToCheck:string) => {
    if(
      !userGroup 
      || !userGroup.permissions 
      || userGroup.permissions.length < 0
    ) return false;

    var permissions = userGroup.permissions;
    var found = false;

    permissions.map((permission:any, key:number) => {
      if(permission.key === permissionToCheck) {
        found = true 
      }
    });
    return found;
  }

  const _handlePermissionSwith = async (e:any, key:string) => {
    var permissions = [];

    if(
      userGroup
      && userGroup.permissions
      && userGroup.permissions.length > 0
    ) {
      permissions = userGroup.permissions;
    }

    if(e) permissions.push({
      users_groups_uuid: userGroup.uuid,
      key: key
    })

    else {
      // remove the key from the array
      var tempPermission:any[] = [];

      permissions.map((permission:any, mkey:number) => {
        if(permission.key !== key) tempPermission.push(permission);
      })

      permissions = tempPermission;
    }

    setUserGroup({
      ...userGroup,
      permissions: permissions
    });
  }

  const deleteUserGroup = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = userGroup?.uuid;
    
    var res:any = await EV.deleteUserGroup();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }
  
  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    notFound={notFound}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(userGroup.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteUserGroup();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(userGroup?.uuid) ? 'Manage User Group' : 'New User Group'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={userGroup?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(undefined)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={userGroup}
      onFinish={processSubmit}
    >
      <Form.Item
        label={'Name'} 
        name={'name'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setUserGroup({
            ...userGroup,
            name: e.target.value
          })}
        />
      </Form.Item>

      <Divider dashed />

      <Typography.Title level={3} children={'Group Permissions'} />

      {(newUserGroup) ? <Alert
        type={'warning'}
        showIcon
        message={'Save the record to enable group permissions'}
      /> : <>
        <Row gutter={15} style={{paddingTop: 10}}>
          <Col span={8}>
            <PermissionToggle 
              label={'Manage System'}
              value={getPermissionValue('manageSystem')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageSystem')}
            />
            <PermissionToggle 
              label={'Manage Settings'}
              value={getPermissionValue('manageSettings')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageSettings')}
            />
            <PermissionToggle 
              label={'Manage Users'}
              value={getPermissionValue('manageUsers')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageUsers')}
            />
            <PermissionToggle 
              label={'Manage Drop Off'}
              value={getPermissionValue('manageDropOff')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageDropOff')}
            />
            <PermissionToggle 
              label={'Manage Activities'}
              value={getPermissionValue('manageActivities')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageActivities')}
            />
          </Col>
          <Col span={8}>
            <PermissionToggle 
              label={'Manage Containers'}
              value={getPermissionValue('manageContainers')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageContainers')}
            />
            <PermissionToggle 
              label={'Manage Packing Orders'}
              value={getPermissionValue('managePackingOrders')}
              onChange={(e:any) => _handlePermissionSwith(e, 'managePackingOrders')}
            />
            <PermissionToggle 
              label={'Manage Packing Plans'}
              value={getPermissionValue('managePackingPlans')}
              onChange={(e:any) => _handlePermissionSwith(e, 'managePackingPlans')}
            />
            <PermissionToggle 
              label={'Manage Cargoes'}
              value={getPermissionValue('manageCargoes')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageCargoes')}
            />
            <PermissionToggle 
              label={'Manage Documents'}
              value={getPermissionValue('manageDocuments')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageDocuments')}
            />
          </Col>
          <Col span={8}>
            <PermissionToggle 
              label={'Manage Contacts'}
              value={getPermissionValue('manageContacts')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageContacts')}
            />
            <PermissionToggle 
              label={'Manage Exporters'}
              value={getPermissionValue('manageExporters')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageExporters')}
            />
            <PermissionToggle 
              label={'Manage Comments'}
              value={getPermissionValue('manageComments')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageComments')}
            />
            <PermissionToggle 
              label={'Manage Invoices'}
              value={getPermissionValue('manageInvoices')}
              onChange={(e:any) => _handlePermissionSwith(e, 'manageInvoices')}
            />
          </Col>
        </Row>
      </>}

      <div style={{paddingBottom: 30}} />
      
      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}