/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { InvoiceProps } from "../form/general";
import { useParams } from "react-router-dom";
import EVAPI from "../../../../lib/ev_lib/main";
import { URLQuery } from "../../../../util/url_queries";
import moment from "moment";
import { Alert, Button, Form, Input, Skeleton, Typography } from "antd";

export interface InvoiceItemProps {
  uuid?: string | undefined,
  invoices_uuid?: string,
  cargoes_uuid?: string | undefined,
  weight?:string|number,
  item?: string | undefined,
  description?: string | undefined,
  taxable?: 'y' | 'n',
  billable?: 'y' | 'n',
  listing_order?: string | number | undefined,
  price?: string | number,
  quantity?: string | number,
  total?:number|string,
}

export default function InvoiceIndustrialView(props:any) {
  // set the invoice information
  require('./industrial_style.css');
  
  const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  let { uuid } = useParams<any>();
  let urlAccessCode = URLQuery('accessCode', window);

  // let's get the information we need
  const [invoice, setInvoice] = useState<InvoiceProps|undefined>({
    uuid: uuid||undefined,
    reference_number: undefined
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]>([]);

  const [accessCodeMissing, setAccessCodeMissing] = useState<boolean>(false);
  const [accessCodeRequired, setAccessCodeRequired] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string|null>(urlAccessCode);

  const [container, setContainer] = useState<any>(undefined);
  const [packingOrders, setPackingOrders] = useState<any>(undefined);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemProps[]|undefined>(undefined);

  const [pieces, setPieces] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      if(accessCode || localStorage.getItem('auth_token')) {
        getInvoice();
      }
      else {
        setAccessCodeRequired(true);
      }
    }, 2000);
  }, []);

  const getInvoice = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;
    EV.access_code = accessCode;
    EV.guest = localStorage.getItem('auth_token') ? false : true;

    var res:any = await EV.getInvoiceIndustrial();
    
    if(!res) {
      // handle error
    }

    else if(res.code === 500) {
      // handle error
    }

    else if(res.data) {
      setInvoice(res.data.invoice);
      setInvoiceItems(res.data.invoice_items);
      setContainer(res.data.containers[0]);
      setPackingOrders(res.data.packing_orders);

      var invoiceItems = res.data.invoice_items;

      try {
        if(typeof invoiceItems !== 'object') {
          invoiceItems = JSON.parse(invoiceItems);
        }

        var counter = 0;

        for(var i = 0; i < invoiceItems.length; i++) {
          if(invoiceItems[i].cargoes_uuid) {
            ++counter;
          }
        }

        setPieces(counter);
      }
      catch(e) {
        console.error(
          'Failed to parse invoice items',
          invoiceItems,
          e
        );
      }
    }

    else {
      setAccessCodeRequired(true);
    }

    setIsLoading(false);
    return null;
  }

  const _renderWeight = () => {
    if(!invoiceItems) return 0;

    var weight = 0;

    for(var i = 0; i <= invoiceItems?.length; i++) {
      if(invoiceItems[i]?.weight) {
        weight = weight + Number(invoiceItems[i]?.weight)
      }
    }

    return weight.toFixed(2);
  }

  const _renderCompanyInfo = () => {
    if(!invoice?.company_info) return '';

    var companyInfo = JSON.parse(invoice.company_info);

    return <>
      <h1 style={{paddingBottom: 10}}>
        {companyInfo.company_name}
      </h1>
      <div>
        <div>{companyInfo.company_address_1}</div>
        {companyInfo.company_address_2 && <div>
          {companyInfo.company_address_2}
        </div>}
        <div>{companyInfo.company_city}, {companyInfo.company_state}, {companyInfo.company_country} <strong>{companyInfo.company_zip_code}</strong></div>
        <div>
          <strong>Phone:</strong> {companyInfo.company_phone_country_code} {companyInfo.company_phone}
          <span style={{margin: '0 5px'}}>|</span> 
          <strong>Email:</strong> {companyInfo.company_email}
        </div>
      </div>
    </>
  }

  const _renderExporterInfo = () => {
    if(!invoice?.exporter_info) return '';

    var exporterInfo = JSON.parse(invoice.exporter_info);

    return <>
      <h4 style={{paddingBottom: 10}}>
        {exporterInfo.company_name}
      </h4>
      <div>
        <div>{exporterInfo.street_address}</div>
        {exporterInfo.address_2 && <div>
          {exporterInfo.address_2}
        </div>}
        <div>{exporterInfo.city}, {exporterInfo.state}, {exporterInfo.country} <strong>{exporterInfo.zip_code}</strong></div>
        <div>
          <strong>Phone:</strong> {exporterInfo.phone_country_code} {exporterInfo.phone}
          <span style={{margin: '0 5px'}}>|</span> 
          <strong>Email:</strong> {exporterInfo.email}
        </div>
      </div>
    </>
  }

  if(accessCodeRequired) return <div className="access-code-container industrial-invoice">
    <Typography.Title level={2}>Access Code Required</Typography.Title>

    <Alert
      message={'This file is protect with an access code. Please check '
        + 'Your email for the access code or request a new link from '
        + 'the sender.'}
      type='error'
    />

    <div style={{marginTop: 20}} />
    
    <Form.Item
      name={'invoices_uuid'}
      required
    >
      <Input.Password
        placeholder={'6 digits security code'}
        value={`${accessCode}`}
        status={(accessCodeMissing) ? 'error' : undefined}
        onChange={(e) => {
          setAccessCodeMissing(false);
          setAccessCode(e.target.value)
        }}
      />
    </Form.Item>

    <Button 
      type={"primary"}
      loading={isLoading}
      onClick={() => {
        if(!accessCode || accessCode.length < 6) {
          setAccessCodeMissing(true)
        }
        else {
          setAccessCodeRequired(false);
          setInvoice(undefined);
          getInvoice();
        }
      }}
    >
      Access
    </Button>
  </div>

  if(
    !invoice
    || !invoiceItems
    || !container
    || !packingOrders
  ) return <div 
    style={{
      display: 'flex', 
      justifyContent: 'center',
      margin: '0 auto',
      minWidth: 840,
      maxWidth: 1200,
    }}
  >
    <Skeleton />
  </div>

  return <div className='doc-page industrial-invoice'>
    <div className={'document-action-bar execlude-print'}>
      <button className={'document-button'} onClick={() => {
        var doc = new jsPDF("p", "mm", [1000, 1400]);
        var pdfjs:any= document.querySelector('#element-to-print');

        doc.html(
          pdfjs, {
            callback: function(doc) {
              doc.save(`invoice_${Date.now()}.pdf`);
            },
            autoPaging: "text",
            margin: 10,
            x: 12,
            y: 12
          }
        );    
      }}>
        Save as PDF
      </button>
    </div>
    <div className="printable-cotnainer">
      <div className="industrial-invoice" id={'element-to-print'}>
        <table className="container table">
          <tr>
            <td valign="top" width="60%">
              {_renderCompanyInfo()}
            </td>
            <td align="right">
              <table className="industrial-table">
                <tr className="header">
                  <td width={"60%"}>
                    Invoice #
                  </td>
                  <td width={"40%"}>
                    Issue Date
                  </td>
                </tr>
                <tr>
                  <td>
                    {invoice.reference_number||invoice.uuid?.substring(invoice.uuid.length - 10)}
                  </td>
                  <td>
                    {moment(invoice.issue_date).format('Do MMM YYYY')}
                  </td>
                </tr>
                <tr className="header">
                  <td>
                    Customer Reference #
                  </td>
                  <td>
                    Due Date
                  </td>
                </tr>
                <tr>
                  <td>
                    STT-11101
                  </td>
                  <td>
                    {moment(invoice.due_date).format('Do MMM YYYY')}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div style={{borderTop: '1px solid #000', marginBottom: 5, margin: '0 5px'}} />

        <table width="100%">
          <tr>
            <td width="50%" valign="top">
              <table className="industrial-table">
                <tr className="header">
                  <td>Bill to:</td>
                </tr>
                <tr>
                  <td style={{paddingBottom: 25}}>
                    {_renderExporterInfo()}
                  </td>
                </tr>
                <tr className="header">
                  <td>Consignee:</td>
                </tr>
                <tr>
                  <td style={{paddingBottom: 25}}>
                    <div>
                      <strong>{container.consignees_company_name||'n/a'}</strong>
                    </div>
                    <div>{container.consignees_street_address||'n/a'}</div>
                    {container.consignees_address_2 && <div>
                      {container.consignees_address_2}
                    </div>}
                    <div>
                      {container.consignees_city||'n/a'}, {container.consignees_state||'n/a'}, {container.consignees_country||'n/a'} {container.consignees_zip_code||'n/a'}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td valign="top">
              <table className="industrial-table">
                <tr className="header">
                  <td colSpan={2}>Additional Information:</td>
                </tr>
                <tr>
                  <td className="sub-title-column" width="40%">
                    Move Type:
                  </td>
                  <td>
                    {container.move_type}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Booking #:
                  </td>
                  <td>
                    {container.booking_number}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    MBL / MAWB / PD / WR #: 
                  </td>
                  <td>
                    {container.uuid}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column" valign="top">
                    HBL #:
                  </td>
                  <td valign="top">
                    {(packingOrders) && packingOrders.map((po:any, index:number) => {
                      return `${po.exporters_reference_id}-${po.reference_number}, `
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Carrier:
                  </td>
                  <td>
                    {container.steam_ship_lines_company_name}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Vessel:
                  </td>
                  <td>
                    {container.vessel}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Origin:
                  </td>
                  <td>
                    {container.port_of_export_city}, {container.port_of_export_country}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Destination:
                  </td>
                  <td>
                    {container.destination_city}, {container.destination_country}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Departure Date:
                  </td>
                  <td>
                    {moment(container.departure_date).format('Do MMM YYYY')}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Arrival Date:
                  </td>
                  <td>
                    {moment(container.arrival_date).format('Do MMM YYYY')}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Pieces:
                  </td>
                  <td>
                    {pieces}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Weight:
                  </td>
                  <td>
                    {_renderWeight()} KGS
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Container Type / Number:
                  </td>
                  <td>
                    {container.equipments_types_label} / {container.container_number}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <table className="industrial-table" cellSpacing={5}>
          <tr className="header">
            <td width="40%">Description</td>
            <td align="right">Qty</td>
            <td width="20px">Unit</td>
            <td width="10px">Taxable</td>
            <td align="right">Rate</td>
            <td align="right">Amount</td>
          </tr>
          {invoiceItems?.map((item:InvoiceItemProps, index:number) => {
            return <tr>
              <td>
                <div>{item.item}</div>
                <div>{item.description}</div>
              </td>
              <td align="right">
                {item.quantity}
              </td>
              <td>Ea.</td>
              <td>
                {(item.taxable === 'y') ? 'Yes' : 'No'}
              </td>
              <td align="right">
                {formatter.format(Number(item.price))}
              </td>
              <td align="right">
                {formatter.format(Number(item.total))}
              </td>
            </tr>
          })}
          <tr>
          </tr>
        </table>

        <div style={{borderTop: '1px dashed #000', marginBottom: 5, margin: '0 10px'}} />

        <div style={{marginBottom: 25}} />

        <table width="100%" cellPadding={3}>
          <tr>
            <td 
              width="70%" 
              valign="top" 
              align="right"
            >
              Sub Total (USD)
            </td>
            <td 
              width="30%" 
              valign="top" 
              align="right"
              style={{paddingRight: 15}}
            >
              {formatter.format(Number(invoice.sub_total))}
            </td>
          </tr>
          <tr>
            <td 
              valign="top" 
              align="right"
            >
              Tax Total (USD)
            </td>
            <td 
              valign="top" 
              align="right"
              style={{paddingRight: 15}}
            >
              {formatter.format(Number(invoice.tax_total))}
            </td>
          </tr>
          <tr>
            <td 
              valign="top" 
              align="right"
              style={{fontSize: 15, fontWeight: 'bold'}}
            >
              Total (USD)
            </td>
            <td 
              valign="top" 
              align="right"
              style={{fontSize: 15, fontWeight: 'bold', paddingRight: 15}}
            >
              {formatter.format(Number(invoice.grand_total))}
            </td>
          </tr>
          <tr>
            <td 
              valign="top" 
              align="right"
            >
              Paid Total (USD)
            </td>
            <td 
              valign="top" 
              align="right"
              style={{paddingRight: 15}}
            >
              {formatter.format(Number(invoice.total_paid))}
            </td>
          </tr>
          <tr>
            <td 
              valign="top" 
              align="right"
              style={{fontSize: 15, fontWeight: 'bold'}}
            >
              Remaining Balance (USD)
            </td>
            <td 
              valign="top" 
              align="right"
              style={{fontSize: 15, fontWeight: 'bold', paddingRight: 15}}
            >
              {formatter.format(Number(invoice.grand_total) - Number(invoice.total_paid))}
            </td>
          </tr>
        </table>

        <div style={{marginBottom: 25}} />

        <div style={{borderTop: '1px dashed #000', marginBottom: 5, margin: '0 10px'}} />

        <table width="100%">
          <tr>
            <td width="50%" valign="top">
              <table className="industrial-table" cellSpacing={5}>
                <tr className="header">
                  <td>Return Portion</td>
                </tr>
                <tr>
                  <td className="multiple-lines-text">
                    {invoice.remittince}
                  </td>
                </tr>
              </table>
            </td>
            <td width="50%" valign="top">
              <table className="industrial-table" cellSpacing={5}>
                <tr className="header">
                  <td colSpan={2}>Invoice Summary</td>
                </tr>
                <tr>
                  <td width="40%" className="sub-title-column">
                    Invoice #
                  </td>
                  <td>
                    {invoice.reference_number||invoice.uuid?.substring(invoice.uuid.length - 10)}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Customer Name:
                  </td>
                  <td>
                    Exporter name
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Amount Due (USD):
                  </td>
                  <td>
                    {formatter.format(Number(invoice.grand_total) - Number(invoice.total_paid))}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Issue Date:
                  </td>
                  <td>
                    {moment(invoice.issue_date).format('Do MMM YYYY')}
                  </td>
                </tr>
                <tr>
                  <td className="sub-title-column">
                    Due Date:
                  </td>
                  <td>
                    {moment(invoice.due_date).format('Do MMM YYYY')}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

      </div>
    </div>
  </div>
}