/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Alert, Button, Col, Form, Input, Layout, Row, Space, theme, Typography } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";

import EVAPI from '../lib/ev_lib/main';

import { config } from '../config';
import { URLQuery } from '../util/url_queries';
import { 
  convertUserSettingsArrayDatasetToObject,
  convertPermissionsKeyDatasetToStringArray
} from '../lib/ev_lib/data_processors';
import { CloseCircleOutlined, RightCircleFilled } from '@ant-design/icons';
import { red } from '@ant-design/colors';

export interface LoginProfileProps {
  auth_token?:string,
  device_uuid?:string,
  uuid?:string,
  username?:string,
  users_groups_uuid?:string,
  is_blocked?:'y'|'n'|null|undefined,
  type?:'management'|'exporter',
  contacts_uuid?:string,
  name_prefix?:string,
  first_name?:string,
  last_name?:string,
  email?:string,
  phone_country_code?:string,
  phone?:string,
  company_name?:string,
}

export default function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string|null>(null);
  const [password, setPassword] = useState<string|null>(null);

  const [domain, setDomain] = useState<string|undefined>(undefined);

  const [errors, setErrors] = useState<any[]>([]);

  // this is a temporary solution to allow users with no MFA 
  // to use the legacy method
  const [allowLegacyMethod, setAllowLegacyMethod] = useState<boolean>(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const logout = URLQuery('logout', window);

  if(logout === 'true') {
    localStorage.removeItem('target_domain');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('device_uuid');
    localStorage.removeItem('profil');

    // must remove the logout flag from the URL to prevent the system
    // from removing the localstorage when the component refresh to
    // process the login
    window.location.href = process.env.REACT_APP_PORTAL_URI + 'login';
  }

  useEffect(() => {
    if(!domain) {
      var urlDomain = URLQuery('domain', window);
      setDomain(urlDomain);
      form.setFieldValue('domain', urlDomain);
    }
  }, []);

  // set the domain then attempt the login
  const processDomain = async () => {
    if(isLoading) return false;
    setIsLoading(true);

    var res:any = undefined;

    if(process.env.REACT_APP_FORCED_CORE_URI) {
      // this sectino will simulate a success response
      res = {
        status: 'success',
        data: {
          target: process.env.REACT_APP_FORCED_CORE_URI,
          uuid: 'demo'
        }
      }
    }
    else {
      var EV:any = new EVAPI;
      EV.debug = process.env.NODE_MODE === 'development' ? true : false;
      EV.lang = 'en';
      EV.baseAPI = process.env.REACT_APP_CORE_DISPATCH_URI;
      EV.domain = domain;
  
      res =  await EV.getDomain();
    }

    if(!res) {
      setErrors([{
        en: 'Failed to connect to the server'
      }]);

      setIsLoading(false);
    }

    else if(res.status !== 'success') {
      setErrors(res.errors);
      setIsLoading(false);
    }

    else {
      // store the end target
      localStorage.setItem('target_domain', res?.data?.target);
      localStorage.setItem('core_uuid', res?.data?.uuid);
      login();
    }

    return;
  }

  const login = async (skipMFA:boolean = false) => {
    // reset the errors
    setErrors([]);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.lang = 'en';
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.username = username;
    EV.password = password;

    // this is important to prevent looping
    if(!skipMFA) {
      EV.newMethod = true;
    }

    var res:any = await EV.login();
    setIsLoading(false);

    if(!res) {
      setErrors([{
        en: 'Failed to connect to the server'
      }]);

      return null;
    }

    else if(res.status !== 'success') {
      setErrors(res.errors);
      return null;
    }

    var profile:any = res.data;
    var tempErrors:any[] = [];

    // check profile requirements
    if(!profile) {
      tempErrors.push({ 
        en: 'Failed to download the profile' 
      })
    }

    if(!profile.device_uuid) {
      tempErrors.push({ 
        en: 'Server did not provide a device ID' 
      })
    }

    if(tempErrors.length > 0) {
      setErrors(tempErrors);
      return;
    }
    
    // the code may not complete the normal login without
    // the MFA to avoid loss of the device ID it is stored now
    localStorage.setItem('device_uuid', profile?.device_uuid);
    
    if(profile.additional_auth_required) {
      if(
        !profile.multi_factor_authentication_methods
        || profile.multi_factor_authentication_methods.length < 1
      ) {
        login(true);
        return;
      }

      let redirect = URLQuery('redirect', window);

      // here
      navigate('/auth/mfa', {
        state: {
          mfa_methods: profile.multi_factor_authentication_methods,
          redirect: redirect
        }
      });

      return;
    }

    if(!profile.auth_token) {
      tempErrors.push({ 
        en: 'Server did not provide an authentication token.' 
      })
    }

    if(profile.is_blocked !== 'n') {
      tempErrors.push({ 
        en: 'Account blocked from accessing the system.' 
      })
    }

    if(tempErrors.length > 0) {
      setErrors(tempErrors);
      return null;
    }

    localStorage.setItem('auth_token', profile?.auth_token);
    localStorage.setItem('profile', JSON.stringify(profile));

    getUser(profile.uuid);
    return;
  }

  const getUser = async (uuid:string) => {
    var tempErrors = [];

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;
    
    var res:any = await EV.getUser();

    if(
      !res
      || res.status !== 'success'
    ) {
      tempErrors.push({ 
        en: 'Failed to download user information' 
      })
    }

    if(tempErrors.length > 0) {
      setErrors(tempErrors);

      return null;
    }
    
    var tempUserSettings = res.data.settings;
    tempUserSettings = convertUserSettingsArrayDatasetToObject(tempUserSettings);
    localStorage.setItem('user_settings', JSON.stringify(tempUserSettings));

    var tempPermissions = res.data.permissions;
    tempPermissions = convertPermissionsKeyDatasetToStringArray(tempPermissions);
    localStorage.setItem('user_permissions', JSON.stringify(tempPermissions));

    let redirect = URLQuery('redirect', window);
    let currentPath = window.location.pathname;
    
    if(redirect) {
      window.location.href = process.env.REACT_APP_PORTAL_URI + redirect;
    }
    else if (currentPath === '/login') {
      window.location.href = process.env.REACT_APP_PORTAL_URI || '';
    }
    else {
      window.location.reload();
    }
  }

  return <Layout>
    <Content
      className="full-screen-container"
      style={{background: colorBgContainer}}
    >
      <Row style={{width: '100%'}}>
        <Col span={10} offset={8}>
          <div style={{marginBottom: 0, width: 200, height: 40}}>
            <img src={require('../framework/assets/logo.png')} style={{height: '100%'}} />
          </div>

          <Typography.Title level={3}>Welcome to eVessel</Typography.Title> 
          
          {(errors && errors.length > 0) && <Alert
            type={'error'}
            message={'Login Error'}
            description={<>
              <ul>
                {errors.map((error, key) => {
                  if(typeof error === 'object') {
                    error = error.en
                  }

                  return <li>{error}</li>;
                })}
              </ul>
              {(allowLegacyMethod) && <Button
                onClick={processDomain}
                children={'Continue Without MFA'}
                icon={<RightCircleFilled />}
                iconPosition='end'
              />}
            </>}
            closable
            onClose={() => setErrors([])}
            style={{marginBottom: 20}}
          />}
          
          <Form
            form={form}
            layout="vertical"
            style={{width: '100%'}}
            onFinish={processDomain}
          >
            <Form.Item 
              label={'Domain'} 
              name={'domain'}
              rules={[{ required: true, message: 'Field required'}]}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e:any) => setDomain(e.target.value)}
              />
            </Form.Item>
            <Form.Item 
              label={'Username'} 
              name={'username'}
              rules={[{ required: true, message: 'Field required'}]}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e:any) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item 
              label={'Password'} 
              name={'password'}
              rules={[{ required: true, message: 'Field required'}]}
            >
              <Input.Password 
                onChange={(e:any) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type={"primary"} 
                htmlType={"submit"}
                loading={isLoading}
              >
                Login
              </Button>
              <Button 
                type={"link"} 
                htmlType={"button"}
                style={{marginLeft: 10}}
              >
                Lost my password
              </Button>
            </Form.Item>
          </Form>

          <div style={{marginTop: 25}} />

          <Typography.Text 
            type={'secondary'}
            style={{width: '100%', marginTop: 50}} 
            className={'small-font'}
          >
            eVessel Platform version {config.version}
          </Typography.Text>
        </Col>
      </Row>
    </Content>
  </Layout>
}

