/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Button, Col, Divider, Form, Input, MessageArgsProps, Row, Select, Space, Typography, message } from "antd";
import { LeftCircleFilled, WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { convertSystemSettingsArrayDatasetToObject } from '../../lib/ev_lib/data_processors';
import { setClearFormFields } from '../../util/clear_empty';
import { URLQuery } from '../../util/url_queries';

export default function SettingsGeneral(props:any) {
  const navigate = useNavigate();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
  const [isUpdatingSettings, setIsUpdatingSettings] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined)

  const [downloadedSettings, setDownloadedSettings] = useState<any[]>([]);
  const [settings, setSettings] = useState<any>(undefined);
  
  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getCountries();
    getSettings();
    setIsReady(true);
  }, []);

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.id, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getSettings = async () => {
    if(isLoadingSettings) return null;
    setIsLoadingSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    EV = Object.assign(EV, settings)
    
    var res:any = await EV.getSettings();
    
    if(!res || res.code !== 200) {
      setLayoutModal({
        open: true,
        title: 'Oops!',
        children: [<>
          <Typography.Paragraph>Failed to load the system settings.</Typography.Paragraph>
        </>],
        okText: 'Try Again',
        onOk: () => {
          getSettings();
          setLayoutModal({open: false});
        },
        cancelText: 'Go back',
        onCancel: () => {
          setLayoutModal({open: false});
          navigate(-1)
        }
      })
    }

    else if(res.code === 200) {
      var data = res.data;
      setDownloadedSettings(res.data);

      // build the settings object {uuid, value}
      var tempSettings:any = convertSystemSettingsArrayDatasetToObject(data);
      
      setSettings(tempSettings);
      localStorage.setItem('company_settings', JSON.stringify(tempSettings));
    }

    setIsLoadingSettings(false);
    return null;
  }
  
  const updateSettings = async () => {
    if(isUpdatingSettings) return null;
    setIsUpdatingSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.body = settings;
    
    settings.clear = setClearFormFields(settings)||undefined;
    
    var res:any = await EV.updateSettings();

    if(!res || res.code !== 200) {
      messageApi.open({
        key: 'updateSettingsError',
        type: 'error',
        content: 'Failed to update system settings'
      })
    }

    else if(res.code === 200) {
      messageApi.open({
        key: 'updateSettingsSuccess',
        type: 'success',
        content: 'System settings has been updated'
      })
    }

    setIsUpdatingSettings(false);
    return null;
  }
  
  return <BlankPage
    isLoading={!isReady || isLoadingSettings}
    unauthorized={!authorized}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
    </Space>}
  >    
    {contextHolder}

    <Typography.Title level={1} children={'Settings General'} />

    <Alert
      type={'warning'}
      showIcon
      message={'Save will update all data on the screen.'}
    />

    <Form
      form={form}
      layout="vertical"
      initialValues={settings}
      onFinish={updateSettings}
    >
      <Typography.Title level={2} children={'Company Info'} />
      
      <Typography.Title level={3} children={'Address'} />

      <Row gutter={15}>
        <Col span={18}>
          <Form.Item
            label={'Address'} 
            name={'company_address_1'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setSettings({
                ...settings,
                company_address_1: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Unit, Bld, Apt #'} 
            name={'company_address_2'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setSettings({
                ...settings,
                company_address_2: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={'Country'} 
        name={'company_country'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            company_country: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'State'} 
        name={'company_state'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            company_state: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'City'} 
        name={'company_city'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            company_city: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Zip Code'} 
        name={'company_zip_code'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            company_zip_code: e.target.value
          })}
        />
      </Form.Item>

      <Typography.Title level={3} children={'Contact'} />
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            label={'Phone'} 
            name={'company_phone'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setSettings({
                ...settings,
                'company_phone': e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Fax'} 
            name={'company_fax'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setSettings({
                ...settings,
                company_fax: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title level={3} children={'Legal Licenses'} />

      <Form.Item
        label={'FCM OTI License #'} 
        name={'fmc_oti_license_number'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            fmc_oti_license_number: e.target.value
          })}
        />
      </Form.Item>

      <Divider dashed />

      <Typography.Title level={2} children={'Localization'} />

      <Form.Item
        label={'Timezone'} 
        name={'default_timezone'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            default_timezone: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item
        label={'Date Format'} 
        name={'default_date_format'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            default_date_format: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item
        label={'Time Format'} 
        name={'default_time_format'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSettings({
            ...settings,
            default_time_format: e.target.value
          })}
        />
      </Form.Item>

      <Divider dashed />

      <Typography.Title level={2} children={'Optimization'} />
      
      <Row gutter={[15, 15]} align="top" style={{marginBottom: 15}}>
        <Col span={16}>
          <Form.Item
            label={'Max Width (px)'} 
            name={'image_optimization_max_width'}
            tooltip={"Leave empty to use system default value 800"}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setSettings({
                ...settings,
                image_optimization_max_width: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8} className='inputDescriptionSectionLB'>
          <Typography.Paragraph>
            <Typography.Text strong>
              Max Width
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text>
                This field set the max width or height of the new optimzed image
                for both local optimization and online optimization.
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text>
                The default value is 800 if left empty.
            </Typography.Text>
          </Typography.Paragraph>
        </Col>
      </Row>
      
      <Row gutter={[15, 15]} align="top">
        <Col span={16}>
          <Form.Item
            label={'Quality (1 - 99)'} 
            name={'image_optimization_quality_value'}
            tooltip={"Leave empty to use system default value 60"}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setSettings({
                ...settings,
                image_optimization_quality_value: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8} className='inputDescriptionSectionLB'>
          <Typography.Paragraph>
            <Typography.Text strong>
              Optimization Quality
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text>
                The new quality of the optimized image. The quality can be 1 to 100.
                If set to 100 the system will resize the immage if max width is set 
                but will skip compressing the image quality.
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text>
                The default value is 60 if left empty.
            </Typography.Text>
          </Typography.Paragraph>
        </Col>
      </Row>

      <Divider dashed />

      <Typography.Title level={2} children={'Cargoes'} />

      <Form.Item
        label={'Cargo Default Access Status'} 
        name={'cargoes_default_is_public'}
      >
        <Select 
          placeholder={'Select one'}
          defaultValue={settings?.cargoes_default_is_public||'default'}
          onChange={(value) => setSettings({
            ...settings,
            cargoes_default_is_public: value
          })}
          options={[
            { value: 'n', label: 'Private' },
            { value: 'y', label: 'Public' },
            { value: 'default', label: 'System Default' }
          ]}
        />
      </Form.Item>

      <Form.Item
        label={'Preselect New Cargo Title Country'} 
        name={'cargoes_preselect_title_country'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value, option:any) => setSettings({
            ...settings,
            cargoes_preselect_title_country: option?.label||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={countries||[]}
        />
      </Form.Item>

      <Divider dashed />

      <Typography.Title level={2} children={'Documentations'} />

      <Form.Item
        label={'Documentations Template'} 
        name={'default_documents_template'}
      >
        <Select 
          placeholder={'Select one'}
          defaultValue={settings?.default_documents_template||'template_2'}
          onChange={(value) => setSettings({
            ...settings,
            default_documents_template: value
          })}
          options={[
            { value: 'template_1', label: 'Template 1' },
            { value: 'template_2', label: 'Template 2' }
          ]}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isUpdatingSettings}
          >
            Save
          </Button>
        </Space>
      </Form.Item>


    </Form>
  </BlankPage>
}