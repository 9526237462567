/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import BlankPage, { LayoutModalProps } from '../../framework/blank_page';
import { Button, Col, Divider, Empty, Row, Segmented, Space, Steps, Typography, message } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../config';
import RecordNotFound from '../../framework/states/not_found';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import EVAPI from '../../lib/ev_lib/main';
import Documents from '../../common/documents';
import Comments from '../../common/comments';
import { URLQuery } from '../../util/url_queries';
import { displayName } from '../../lib/ev_lib/builders';

export default function CargoesView(props:any) {
  interface CargoProps {
    uuid: string|undefined,
    packing_orders_uuid?: string|null,
    warehouses_uuid?: string|null,
    warehouses_name?: string|null|undefined,
    warehouses_city?: string|null|undefined,
    warehouses_state?: string|null|undefined,
    warehouses_country?: string|null|undefined,
    exporters_uuid?: string|null,
    exporters_reference_id?: string|null,
    exporters_company_name?: string|null|undefined,
    exporters_name_prefix?: string|null|undefined,
    exporters_first_name?: string|null|undefined,
    exporters_last_name?: string|null|undefined,
    exporters_consignees_uuid?: string|null,
    modules_records_statuses_uuid?: string|null,
    modules_records_statuses_label?: string|null|undefined,
    modules_records_statuses_highlight_type?: string|null|undefined,
    modules_records_statuses_highlight_color?: string|null|undefined,
    date_in?: string|null,
    date_in_obj?: any,
    destination_country?: string|null,
    destination_state?: string|null,
    destination_city?: string|null,
    type?: 'general'|'vehicle'|null,
    is_owner_known?: 'y'|'n'|null,
    quantity?: string|null,
    dimensions?: string|null,
    value?: string|null,
    weight?: string|null,
    insurance_value?: string|null,
    insurance_type?: 'partial coverage'|'full coverage'|'total loss'|'none'|null,
    mbl_notes?: string|null,
    public_notes?: string|null,
    vin_number?: string|null,
    year?: number|string|null,
    make?: string|null,
    model?: string|null,
    fuel_type?: string|null,
    color?: string|null,
    mileages?: number|string|null,
    number_of_keys?: number|string|null,
    has_title?: 'y'|'n'|null,
    title?: string|null,
    title_country?: string|null,
    title_state?: string|null,
    lot_number?: string|null,
    is_salvage?: 'y'|'n'|null,
    is_dismantled?: 'y'|'n'|null,
    is_running?: 'y'|'n'|null,
    documents?: any[]|null,
    is_public?: 'y'|'n'|null
  }

  const navigate = useNavigate();

  let { uuid } = useParams<any>();

  let profile:any = localStorage.getItem('profile');

  const [messageApi, contextHolder] = message.useMessage();

  const [cargoesStatuses, setCargosStatuses] = useState<any[]>([]);
  const [cargoStatusIndex, setCargoStatusIndex] = useState<number|undefined>(undefined);
  const [isLoadingCargoesStatuses, setisLoadingCargoesStatuses] = useState<boolean>(false);
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isManagement, setIsManagement] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  const [cargo, setCargo] = useState<CargoProps>({
    uuid: uuid||undefined,
    type: 'general',
    date_in_obj: undefined
  });
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management', 'exporter'].includes(profile.type)) {
        setAuthorized(true);
      }
      
      if(['management'].includes(profile.type)) {
        setIsManagement(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getCargoesStatuses();

    if(uuid) getCargo();

    setTimeout(() => setIsReady(true), 1500);
  }, []);

  useEffect(() => {
    if(cargoesStatuses?.length > 0) {
      cargoesStatuses.map((status:any, key:number) => {
        if(status.value === cargo.modules_records_statuses_uuid) {
          setCargoStatusIndex(key);
        }
      })
    }
  }, [cargo])

  const getCargoesStatuses = async () => {
    if(isLoadingCargoesStatuses) return null;
    setisLoadingCargoesStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'cargoes';
    EV.orderDirection = 'ASC';
    EV.showAll = true;

    var res:any = await EV.getModulesRecordsStatuses();

    setisLoadingCargoesStatuses(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          title: item.label,
          description: item.description
        });
      });
    }

    setCargosStatuses(sortedData);
    return null;
  }

  const getCargo = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getCargo();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if ([400, 404].includes(res.code)) {
      // record not found
      setNotFound(true);
    }

    else if(res.data) {
      // convert the date recieved from the server to
      // from date tiem to date only format
      var data = res.data;
      
      if(data.date_in) {
        var convertedDate = momentTz(data.date_in).tz(moment.tz.guess());
        data.date_in_obj = dayjs(convertedDate.format())
        
        try {
          data.date_in = convertedDate.format();
          data.date_in = data.date_in.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert date_in time', data.date_in, e
          );
        }
      }

      // set the cargo status index
      setCargo(data);
    }

    setIsLoading(false);
    return null;
  }

  const _renderYesNo = (value:string|null|undefined) => {
    if(value === 'n') return 'No';
    else if (value === 'y') return 'Yes';
    else return '-';
  }
  
  return <BlankPage
    isLoading={!isReady || isLoading}
    unauthorized={!authorized}
    notFound={notFound}
    padded
    showSideMenu
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
    </Space>}
    rightSideComponent={() => {
      if(!isManagement) return null;

      return <Comments 
        module={'cargoes'}
        moduleUuid={cargo.uuid}
      />
    }}
  >
    {contextHolder}

    <Typography.Title level={1} children={'Cargo Details'} />

    <div style={{marginTop: '30px'}}>
      <Steps
        type="navigation"
        current={cargoStatusIndex}
        items={cargoesStatuses}
      />
    </div>

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'ID:'} /></div>
        <div><Typography.Text children={cargo?.uuid} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Exporter:'}/></div>
        <div>
          <Typography.Text children={cargo?.exporters_company_name} /><br />
          <Typography.Text 
            italic 
            style={{textTransform: 'capitalize'}} 
            children={displayName(cargo?.exporters_name_prefix, cargo?.exporters_first_name, cargo?.exporters_last_name)} 
          />
        </div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={11}>
        <div><Typography.Text strong children={'Warehouse:'}/></div>
        <div>
          <Typography.Text children={`${cargo?.warehouses_city||''}, ${cargo?.warehouses_state||''}, ${cargo?.warehouses_country||''}`} /><br />
          <Typography.Text italic strong children={cargo?.warehouses_name||''} />
          <span style={{marginLeft: 10}}>
            <Typography.Text italic children={'@ ' + (cargo?.date_in||'')} />
          </span>
        </div>
      </Col>
      <Col span={1}>
        <Typography.Title level={5} children={'\u2794'} />
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Destination:'} /></div>
        <div>
          <Typography.Text children={`${cargo?.destination_city||''}, ${cargo?.destination_state||''}, ${cargo?.destination_country||''}`} />
        </div>
      </Col>
    </Row>

    <Divider dashed/>

    {(cargo?.type === 'vehicle') && <>
      <Typography.Title level={2} children={'Vehicle Details'} />

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'VIN #:'} /></div>
          <div><Typography.Text children={cargo?.vin_number||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Make:'} /></div>
          <div><Typography.Text children={cargo?.make||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Model:'} /></div>
          <div><Typography.Text children={`${cargo?.model||'-'} ${cargo?.fuel_type||''}`} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Year:'} /></div>
          <div><Typography.Text children={cargo?.year||'-'} /></div>
        </Col>
      </Row>

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'Color:'} /></div>
          <div><Typography.Text children={cargo?.color||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Mileages:'} /></div>
          <div><Typography.Text children={cargo?.mileages||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Number of Keys:'} /></div>
          <div><Typography.Text children={cargo?.number_of_keys||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'LOT #:'} /></div>
          <div><Typography.Text children={cargo?.lot_number||'-'} /></div>
        </Col>
      </Row>

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'Has Title:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.has_title)} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Title #:'} /></div>
          <div><Typography.Text children={cargo?.title||'-'} /></div>
        </Col>
        <Col span={8}>
          <div><Typography.Text strong children={'Title Origin:'} /></div>
          <div>
            <Typography.Text children={cargo?.title_state||'-'} />
            <Typography.Text children={', '} />
            <Typography.Text children={cargo?.title_country||'-'} />
          </div>
        </Col>
      </Row>

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'Is Salvage:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.is_salvage)} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Is Dismantled:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.is_dismantled)} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Is Running:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.is_running)} /></div>
        </Col>
        <Col span={6}></Col>
      </Row>

      <Divider dashed/>
    </>}

    <Typography.Title level={2} children={'General Details'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={6}>
        <div><Typography.Text strong children={'Is Owner Known:'} /></div>
        <div><Typography.Text children={_renderYesNo(cargo?.is_owner_known)} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Quantity:'} /></div>
        <div><Typography.Text children={cargo?.quantity||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Value:'} /></div>
        <div><Typography.Text children={'$' + Number(cargo?.value).toFixed(2)||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Weight:'} /></div>
        <div><Typography.Text children={(Number(cargo?.weight).toFixed(2)||'-') + ' KG'} /></div>
      </Col>
    </Row>

    <Divider dashed/>

    <Typography.Title level={2} children={'Insurance Details'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'Insurance Type:'} /></div>
        <div><Typography.Text style={{textTransform: 'capitalize'}} children={cargo?.insurance_type} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Insurance Value:'} /></div>
        <div><Typography.Text children={'$' + (cargo?.insurance_value||'-')} /></div>
      </Col>
    </Row>

    <Divider dashed/>

    <Typography.Title level={2} children={'Documents'} />
      
    <Row gutter={10} style={{marginBottom: 20}}>
      {(cargo?.type === 'vehicle' && cargo?.has_title === 'y') && <>
        <Col span={8}>
          <Documents
            purpose={'cargo'}
            purposeUuid={cargo.uuid}
            category={'title'}
            title={'Title Images & Documents'}
            titleLevel={5}
          />
        </Col>
      </>}
      <Col span={(cargo?.type === 'vehicle' && cargo?.has_title === 'y') ? 16 : 24}>
        <Documents
          purpose={'cargo'}
          purposeUuid={cargo.uuid}
          category={'general'}
          title={'Cargo Images & Documents'}
          titleLevel={5}
        />
      </Col>
    </Row>

    <Divider dashed/>

    <Typography.Title level={2} children={'Notes'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={24}>
        <div><Typography.Text children={cargo?.public_notes} /></div>
      </Col>
    </Row>
  </BlankPage>
}
